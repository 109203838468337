.woocommerce-notices-wrapper {
    .woocommerce-message {
        background: map-get($grey, light);
        padding: 10px 25px;
        margin-top: 15px;
        margin-bottom: 15px;
        border-left: 4px solid map-get($grey, regular);
        &[role="alert"] {
            border-color: map-get($primaryColors, green);
        }
    }
}

ul.woocommerce-error {
    background: map-get($stateColors, error);
    padding: 30px;
    color: #fff;
    margin-bottom: 30px;
    li {
        margin-bottom: 10px;
        strong {
            font-weight: bold;
        }
    }
}