.woocommerce-account.woocommerce-page.logged-in {
    padding-top: 150px;
    main {
        > section {
            @include container();
            .woocommerce {
                display: flex;
                flex-wrap: wrap;
                > .woocommerce-MyAccount-navigation {
                    width: 100%;
                    max-width: 240px;
                    background: map-get($grey, light);
                    padding: 25px 40px;
                    > ul {
                        > li {
                            font-size: 1.1em;
                            margin-bottom: 10px;
                            &.is-active {
                                font-weight: bold;
                                a {
                                    color: #000;
                                }
                            }
                        }
                    }
                }
                > .woocommerce-MyAccount-content {
                    padding: 40px 0;
                    width: 100%;
                    // // margin-left: 100px;
                    // // max-width: calc(100% - 480px);
                    .addresses {
                        margin-top: 30px;
                        display: flex;
                        justify-content: space-between;
                    }
                    .shop_table {
                        width: 100%;
                        vertical-align: middle;
                        > thead {
                            tr {
                                > th {
                                    font-weight: bold;
                                    text-align: left;
                                    padding: 10px 0;
                                    vertical-align: middle;
                                    border-bottom: 1px solid map-get($grey, light);
                                }
                                > .product {
                                    // &-remove {}
                                    // &-thumbnail {}
                                    // &-name {}
                                    // &-price {}
                                    // &-quantity {}
                                    &-subtotal {
                                        text-align: right;
                                    }
                                }
                            }
                        }
                        > tbody {
                            > tr {
                                > td {
                                    padding: 10px 0;
                                    vertical-align: middle;
                                    border-bottom: 1px solid map-get($grey, light);
                                    // text-align: center;
                                }
                                > .product {
                                    &-remove { width: 40px; }
                                    &-thumbnail { 
                                        width: 60px;
                                        img {
                                            max-width: 50px;
                                            height: auto;
                                        }
                                    }
                                    // &-name {}
                                    // &-price {}
                                    &-quantity { 
                                        label { display: none; }
                                    }
                                    &-subtotal {
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }

                    form {
                        .button {
                            @include Button();
                        }
                        p {
                            margin-bottom: 15px;
                            label {
                                display: block;
                            }
                            .woocommerce-input-wrapper > strong,
                            input[type=text], 
                            input[type=number], 
                            input[type=phone], 
                            input[type=tel], 
                            input[type=password], 
                            input[type=email], 
                            select, 
                            textarea {
                                font-size: 0.9em;
                                font-family: map-get($fonts, copy);
                                display: block;
                                width: calc(100% - 20px);
                                padding: 4px 10px;
                                border: 1px solid map-get($grey, medium);
                                &:focus {
                                    outline: none;
                                    box-shadow: none;
                                }
                            }
                            .woocommerce-input-wrapper > strong {
                                padding: 3px 10px;
                            }
                            
                            &.woocommerce-invalid {
                                .woocommerce-input-wrapper > strong,
                                input[type=text], 
                                input[type=number], 
                                input[type=phone], 
                                input[type=tel], 
                                input[type=password], 
                                input[type=email], 
                                select, 
                                textarea {
                                    border-color: map-get($stateColors, error);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* ********** */

.woocommerce-account.woocommerce-page.not-logged-in {
    padding-top: 150px;
    main {
        > section {
            @include container();
            .woocommerce {
                padding-bottom: 100px;
                #customer_login {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .woocommerce-button {
                        @include Button();
                        font-size: 1em;
                        margin-top: 30px;
                    }
                    .woocommerce-form-login__rememberme {
                        display: block;
                    }

                    > div {
                        width: 100%;
                        max-width: calc(50% - 100px);
                        &:last-child {
                            padding: 50px;
                            border: 1px solid map-get($grey, light);
                        }

                        p {
                            margin-bottom: 15px;
                            label {
                                display: block;
                                margin-bottom: 10px;
                            }
                            .woocommerce-input-wrapper > strong,
                            input[type=text], 
                            input[type=number], 
                            input[type=phone], 
                            input[type=tel], 
                            input[type=password], 
                            input[type=email], 
                            select, 
                            textarea {
                                font-size: 0.9em;
                                font-family: map-get($fonts, copy);
                                display: block;
                                width: calc(100% - 20px);
                                padding: 4px 10px;
                                border: 1px solid map-get($grey, medium);
                                &:focus {
                                    outline: none;
                                    box-shadow: none;
                                }
                            }
                            .woocommerce-input-wrapper > strong {
                                padding: 3px 10px;
                            }
                            
                            &.woocommerce-invalid {
                                .woocommerce-input-wrapper > strong,
                                input[type=text], 
                                input[type=number], 
                                input[type=phone], 
                                input[type=tel], 
                                input[type=password], 
                                input[type=email], 
                                select, 
                                textarea {
                                    border-color: map-get($stateColors, error);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
