@import "./helpers/reset";

$browser-context: 14;
 
$grey: (
    dark: #4A4A4A,
    regular: #888888, 
    medium: #d2d2d2,
    light: #F0F0F0
)!default;

// General common colors
$primaryColors: (
    orange: #FF8202,
    blue: #2c4173,
    yellow: #ffd211,
    black: #000000,
    white: #FFFFFF,
    green: #4ADB11,
    red: #D2001A,
    magenta: #e8165d,
    pink: #FB6666
)!default;

$gl-mq-width: 'max-width' !default;

$gl-mq-list: (
        lg: 80em,
        md: 64em,
        sm: 48em,
        xs: 36em
) !default;

$transition: 0.25s all ease-in-out;

// Sizes
$margin: 20px;
$container: 1350px;

// Specific color for the theme
$colors: (
    main: #114034,
    secondary: #D3E3D2,
    third: #A6D1A9,
    fourth: #A6D1A9,
    copy: #FFFFFF,
    copylight: #FFF
)!default;


$stateColors : (
  error: #FB6666,
  success: #4ADB11,
  info: #2541AD
)!default;

// Default social network colors
$social-network-colors: (
    linkedin: #0077B5,
    twitter: #08A0E9,
    facebook: #3C5898,
    share: #4D4741
) !default;

$blues: (
    dark: #0A1946,
    semi: #1C2F60,
    regular: #5C6FA5,
    light: #BFC5D6,
    transparent: #F0F4FF,
    header: #192D5D
)!default;

// Define fonts for the theme
$fonts: (
    title: ('PP Editorial New', sans-serif),
    program: ('program', sans-serif), 
    copy: ('UniversalSans-450', sans-serif)
)!default;

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";
@import "aos/src/sass/aos.scss";

@import "./helpers/GlightBox";
@import "./helpers/revealer";
@import "./helpers/mixins";
@import "./helpers/plyr";
@import "./helpers/global";
@import "./helpers/leaflet";
@import "./fragment/fragment";
@import "./fragment/header";
@import "./fragment/footer";
@import "./fragment/node";
@import "./fragment/builder/node";

@import "./pages/blog";
@import "./pages/error404";
@import "./pages/home";
@import "./pages/project";
@import "./pages/trails";
@import "./pages/login";
@import "./pages/legal";
@import "./woocommerce/global";

.content.expanded {
  overflow: hidden;
}

.leaflet-popup-content-wrapper {
  width: 250px;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  border-radius: 0;
  box-shadow: none;
  h3 {
    font-size: 16px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 15px;
  }
  p {
    font-size: 14px;
    color: c(secondary);
  }
}

.svg-arrow {
  .background {
    fill: #FFFFFF;
    stroke: #868686;
    transition: $transition;
  }
  .angle {
    transition: $transition;
    stroke: #868686;
  }
  &:hover {
    .background { fill: #000; stroke: #000; }
    .angle { stroke: #ffffff; }
  }
}


