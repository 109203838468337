.woocommerce-cart.woocommerce-page {
    padding-top: 150px;
    main {
        > section {
            @include container();
            .woocommerce {
                > .woocommerce-cart-form {
                    > .shop_table {
                        margin-top: 50px;
                        width: 100%;
                        vertical-align: middle;
                        > thead {
                            tr {
                                > th {
                                    font-weight: bold;
                                    text-align: left;
                                    padding: 10px 0;
                                    vertical-align: middle;
                                    border-bottom: 1px solid map-get($grey, light);
                                }
                                > .product {
                                    // &-remove {}
                                    // &-thumbnail {}
                                    // &-name {}
                                    // &-price {}
                                    // &-quantity {}
                                    &-subtotal {
                                        text-align: right;
                                    }
                                }
                            }
                        }
                        > tbody {
                            > tr {
                                > .actions {
                                    padding: 20px 0;
                                    min-height: 50px;
                                    > .coupon {
                                        display: inline-block;
                                        > label {
                                            font-weight: bold;
                                        }
                                        > input {
                                            margin-left: 10px;
                                            padding: 10px 15px;
                                            border: 1px solid map-get($grey, medium);
                                            border-right: 0px;
                                            &:focus {
                                                box-shadow: none;
                                                outline: none;
                                                border-color: map-get($colors, main);
                                            }
                                        }
                                        > .button {
                                            @include Button();
                                        }
                                    }
                                    > .button { 
                                        display: inline-block;
                                        margin-left: auto; 
                                        @include Button("revert");
                                        float: right;
                                    }
                                }
                            }
                            > tr.cart_item {
                                > td {
                                    padding: 10px 0;
                                    vertical-align: middle;
                                    border-bottom: 1px solid map-get($grey, light);
                                    // text-align: center;
                                }
                                > .product {
                                    &-remove { width: 40px; }
                                    &-thumbnail { 
                                        width: 60px;
                                        img {
                                            max-width: 50px;
                                            height: auto;
                                        }
                                    }
                                    // &-name {}
                                    // &-price {}
                                    &-quantity { 
                                        label { display: none; }
                                    }
                                    &-subtotal {
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }
                }
                /* Bottom page */
                .cart-collaterals {
                    margin-top: 100px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    .cross-sells {
                        > h2 {
                            margin-bottom: 25px;
                        }
                        width: 100%;
                        max-width: 50%;
                    }
                    .cart_totals {
                        position: relative;
                        top: -45px;
                        width: 100%;
                        background: map-get($grey, light);
                        padding: 45px 50px;
                        max-width: calc(420px - 100px);
                        margin-left: auto;
                        .button {
                            @include Button();
                            display: block;
                            text-align: center;
                        }
                        > h2 {
                            margin-bottom: 25px;
                        }
                        .shop_table {
                            width: 100%;
                            tr:first-child {
                                th, td { padding-top: 0px; }
                            }
                            th { 
                                border-bottom: 1px solid map-get($grey, regular); 
                                padding: 10px 0; 
                                text-align: left; 
                            }
                            td { 
                                border-bottom: 1px solid map-get($grey, regular); 
                                padding: 10px 0; 
                                text-align: right; 
                            }
                            .order-total {
                                th, td {
                                    border-bottom: 0px;
                                }
                            }
                            
                            .woocommerce-shipping-totals.shipping {
                                .woocommerce-shipping-destination {
                                    margin-top: 10px;
                                    margin-bottom: 5px;
                                }
                                .woocommerce-shipping-calculator {
                                    .shipping-calculator-form {
                                        padding-top: 15px;
                                        text-align: left;
                                        input {
                                            display: block;
                                            padding: 10px 15px;
                                            width: calc(100% - 30px);
                                            border: 0px;
                                            margin-top: 10px;
                                            &:focus {
                                                box-shadow: none;
                                                outline: none;
                                                border-color: map-get($colors, main);
                                            }
                                        }
                                        .button {
                                            margin-top: 10px;
                                            display: block;
                                            width: 100%;
                                            text-align: center;
                                            font-size: 0.85em;
                                        }
                                    }
                                }
                            }

                            margin-bottom: 25px;
                        }
                    }
                }
            }
        }
    }
}
