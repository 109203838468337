.page-template-tpl-legal {

    main {
        // background: map-get($grey, light);
        > section {
            @include container;
            padding: 150px 0;
            h1 {
                color: map-get($colors, copy);
            }
            h2 {
                font-size: 1.6em;
                color: map-get($colors, copy); 
                margin-top: 40px;
                margin-bottom: 30px; 
            }
            h3 {
                padding-left: 40px;
                font-size: 1.4em;
                color: map-get($colors, copy);
                margin-bottom: 30px;
            }
            p {
                padding-left: 80px;
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0px;;
                }
            }
        }
    }
}

