.archive.tax-product_cat, .post-type-archive-product {
    main {
        > section {
            .site-main {
                display: flex;
                flex-wrap: wrap;
                > .woocommerce-breadcrumb { 
                    width: 100%; 
                    order: 1; 
                }
                > .woocommerce-products-header {
                    width: 100%;
                    order: 2;
                }
                > .woocommerce-result-count {
                    order: 4;
                    margin-left: auto;
                    margin-bottom: 25px;
                }
                > .woocommerce-ordering {
                    order: 3;
                }
                > .products {
                    width: 100%;
                    order: 5;
                }
            }
        }
    }
}
