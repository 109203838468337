// Make the breakpoints
@mixin bp($breakpoint, $mq: $gl-mq-width) {
    $query: map-get($gl-mq-list, $breakpoint);
    @if $query != null {
        @media screen and ($mq: #{nth($query, 1)}) {
            @content;
        }
    }
}

@mixin link() {
    color: c(main);
    transition: $transition;
    text-decoration: underline;
    -webkit-text-underline-position: under;
    -ms-text-underline-position: below;
    text-underline-position: under; 
    -webkit-text-decoration-thickness: 2px;
    text-decoration-thickness: 2px;
    text-transform: uppercase;
    letter-spacing: 0.10em;
    font-weight: 500;
    font-size: 14px;
    &:hover {
        color: c(secondary);
    }
}

@mixin mq($breakpoint, $mq: $gl-mq-width) {
    @media screen and ($mq: $breakpoint) {
        @content;
    }
}

@mixin mqor($first, $second, $mq: $gl-mq-width, $mqor: max-height) {
    @media screen and ($mq: $first), screen and ($mqor: $second) {
        @content;
    }
}

@mixin container($minus: 0) {
    max-width: $container - $minus;
    width: 85%;
    margin: 0 auto;
}

@mixin Button(
    $type: default, 
    $color: #fff, 
    $bg: map-get($colors, main), 
    $colorHover: #fff, 
    $bgHover: map-get($colors, secondary)
) {
    display: inline-block;
    padding: 10px 30px;
    font-family: map-get($fonts, copy);
    text-decoration: none;
    transition: $transition;
    border: 1px solid transparent;
    margin-bottom: 20px;

    @include bp(xs) {
        font-size: 14px;
    }
    
    color: #A6D1A9;
    border-color: #A6D1A9;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    svg {
        position: relative;
        top: 2px;
        margin-left: 10px;
        position: relative;
        left: 0;
        transition: $transition;
    }

    &:hover {
        svg {
            left: 10px;
        }
    }

    @include bp(xs) {
        display: block;
        text-align: center;
    }
}

@mixin fontSize ($base, $sm : 0, $xs: 0) {
    font-size: $base;
    @include bp(sm) { 
        @if $sm != 0 { font-size: $sm;}
        @else { font-size: $base * 0.75; }
    }
    @include bp(xs) { 
        @if $xs != 0 { font-size: $xs;}
        @else { font-size: $base * 0.5; }
    }
}

@function decimal-round ($number, $digits: 0, $mode: round) {
    $n: 1;
    // $number must be a number
    @if type-of($number) != number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) != number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    } @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }
    @if $digits > 0 {
        @for $i from 1 through $digits {
            $n: $n * 10;
        }
    }
    @if $mode == round {
        @return round($number * $n) / $n;
    } @else if $mode == ceil {
        @return ceil($number * $n) / $n;
    } @else if $mode == floor {
        @return floor($number * $n) / $n;
    } @else {
        @warn '#{ $mode } is undefined keyword.';
        @return $number;
    }
}

@function pxtoem($value: 16, $context: $browser-context) {
    @return decimal-round($value / $context, 2 ) * 1em;
}

@function ff($font-family) {
    $query: map-get($fonts, $font-family);
    @if $query != null {
        @return $query;
    }
}

@function c($color) {
    $query: map-get($colors, $color);
    @if $query != null {
        @return $query;
    }
}

@mixin wh($width, $height, $var : none, $value : 0px) {

    $ratio: 0.9;
    $width: $width * $ratio;
    $height: $height * $ratio;

    width: $width;
    height: $height;

    @if $var != none {
        #{$var} : $value;
    }

    @each $name, $v in $gallery-gl-list {
        @include mq($name * 1px) {
            @if ($v == 0) {
                width: 100%;
                height: auto;
                @if $var != none {
                    #{$var} : 0;
                }
            } 
            @else {
                width: $width / $v;
                height: $height / $v;
                @if $var != none {
                    #{$var} : $value / $v;
                }
            }
        }
    }
}
