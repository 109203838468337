.builder-node.twins {
    &.contained {
        > div {
            @include container();
        }
    }
    > div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .builder-node {
            padding: 15px 0;
            &:first-child { padding-top: 0; }
            &:last-child { padding-bottom: 0; }
        }
        
        @include bp(sm) {
            flex-direction: column;
            > * {
                max-width: 100%;
                &:first-child {
                    margin-bottom: 50px;
                }
            }
        }

        h2, h3 {
            font-weight: 600;
            font-size: 26px;
            line-height: 140%;
            margin-bottom: 20px;
        }
    }
    &.full-width {
        padding: 0;
        > div {
            > * {
                width: 100%;
                max-width: calc(50% - 0px);
                @include bp(md) {
                    max-width: calc(50% - 0px);
                }

                @include bp(sm) {
                    max-width: 100%;
                    &:first-child {
                        margin-bottom: 0px;
                    }
                }

                .node.text {
                    padding: 30px 100px;
                    width: calc(100% - 200px);
                    max-width: 500px;
                    @include bp(sm) {
                        max-width: calc(100% - 60px);
                        padding: 50px 30px;
                        width: calc(100% - 60px);
                    }
                }
            }
            > .column_1 {
                .builder-node.text {
                    text-align: right;
                }
            }
            > .column_2 {
                .builder-node.text {
                    text-align: left;
                }
            }
        }
    }
}
