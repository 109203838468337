
.glide {
    .partner-item, .client-item {
        background: #144b3d;
        opacity: 0.95;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        color: #fff;
        .informations {
            h3 {
                font-size: 28px;
                line-height: 1.15em;
                color: #fff;
                margin: 0;
                font-family: ff(program);
                margin-bottom: 15px;
            }
            h4 {
                font-size: 16px;
                font-weight: normal;
                font-family: ff(title);
                color: #A6D1A9;
            }
            .description {
                margin-top: 5px;
            }
            padding: 35px;
        }
        .image {
            background: #D3E3D2;
            padding: 35px 0;
            text-align: center;
            min-height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-height: 125px;
                max-width: 80%;
            }
        }
        img {
            max-width: 100%;
        }
    }
}

.fragment {
    &.header {
        height: 40vh;
        min-height: 465px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        &:before {
            background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
            content: " ";
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 5;
        }
        &.home {
            min-height: 680px;
            height: 55vh;
            transition: $transition;
        }
    }
}
.fragment {
    &.bannerblock {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        top: -120px;
        & + * {
            padding-top: 0!important;
        }
        &.type-a {
            top: -90px;
            .before {
                a { 
                    color: #fff; 
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 145%;
                    letter-spacing: 0.02em;
                    svg {
                        margin-left: 10px;
                    }
                }
            }
            .content {
                > div {
                    > *:first-child {
                        width: 100%;
                        max-width: 30%;
                        @include mq(700px) {
                            max-width: 100%;
                            margin-bottom: 20px;
                        }
                    }
                    > *:last-child {
                        max-width: calc(70% - 50px);
                        @include mq(700px) {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
        &.type-b {
            .content {
                > div {
                    @include mq(700px) {
                    }
                    > *:last-child {
                        width: 100%;
                        max-width: 30%;
                        @include mq(700px) {
                            max-width: 100%;
                        }
                    }
                    > *:first-child {
                        max-width: calc(70% - 50px);
                        @include mq(700px) {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }
            }
        }
        &.real-home {
            top: -150px;
        }
        &.home {
            .before {
                .Button { font-size: 18px; }
                top: -30px;
            }
            .content {
                > div {
                    padding: 0 60px;
                    align-items: center;
                    position: relative;
                    > *:first-child, > *:last-child {
                        max-width: calc(50% - 120px);
                        padding-top: 35px;
                        padding-bottom: 35px;
                        position: relative;
                        @include mq(915px) {
                            padding: 0 0px;
                            max-width: 100%;
                            width: 100%;
                            order: 1;
                            top: 20px;
                        }
                    }
                    > *:last-child {
                        max-width: calc(50% - 180px);
                        @include mq(915px) {
                            padding: 0;
                            max-width: 100%;
                            margin-left: 0px;
                            order: 2;
                            width: 100%;
                            margin-top: 20px;
                        }
                        margin-left: 60px;
                        p {
                            font-size: 18px;
                            line-height: 145%;
                            letter-spacing: 0.02em;
                            @include mq(915px) {
                                font-size: 16px;
                            }
                        }
                    }
                    > .action { 
                        width: 140px;
                        height: 140px;
                        background: url(/app/themes/lumber/static/gfx/fragment/header.content.action.svg) center center no-repeat;
                        background-size: 100% 100%;
                        padding: 25px;
                        display: flex;
                        flex-direction: column;
                        align-content: center;
                        justify-content: center;
                        position: relative;
                        top: 50px;
                        z-index: 10;
                        svg {
                            display: block;
                            margin: 10px auto 0;
                        }
                        font-weight: 600;
                        font-size: 1em;
                        line-height: 145%;
                        text-align: center;
                        letter-spacing: 0.02em;
                        text-transform: uppercase;
                        @include mq(915px) {
                            order: 3;
                            margin-left: auto;
                            margin-right: auto;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        & + section {
            padding-top: 150px;
            // &:before { content: " "; display: block; padding-top: 3.5%; }
        }
        .before {
            position: relative;
            z-index: 10;
            top: -30px;
            text-align: right;
        }
        .content {
            position: relative;
            z-index: 10;
            top: 10%;
            background: c(main);
            a {
                color: c(main);
                transition: $transition;
                svg {
                    *[stroke='#D15E14'] {
                        stroke: c(main);
                        transition: $transition;
                    }
                }
                &:hover {
                    color: c(copy);
                    svg {
                        *[stroke='#D15E14'] {
                            stroke: c(copy);
                        }
                    }
                }
            }
            h1, h2, h3, p, li {
                color: #fff;
                margin-bottom: 0;
            }
            h1, h2 {
                font-style: normal;
                font-weight: 400;
                font-size: 40px;
                line-height: 110%;
                @include mq(1250px) { font-size: 30px; }
                @include mq(915px) { font-size: 26px; }
            }
            > div {
                display: flex;
                padding: 35px 60px;
                justify-content: space-between;
                z-index: 10;
                @include mq(915px) {
                    flex-wrap: wrap;
                }
                > * {
                    width: 100%;
                }
            }
            &:before {
                content: " ";
                background: url(/app/themes/lumber/static/gfx/fragment/header.content.top.svg) top center;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                height: 10px;
                width: 100%;
                display: block;
                position: absolute;
                top: -12px;
                z-index: 5;
            }
            &:after {
                z-index: 5;
                content: " ";
                background: url(/app/themes/lumber/static/gfx/fragment/header.content.bottom.svg) top center;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                height: 10px;
                width: 100%;
                display: block;
                position: absolute;
                bottom: -12px;
            }
        }
    }
}
// .fragment {
//     &.header {
//         height: 40vh;
//         min-height: 465px;
//         background-position: center center;
//         background-repeat: no-repeat;
//         background-size: cover;
//         display: flex;
//         flex-direction: column;
//         justify-content: flex-end;
//         position: relative;
//         &:before {
//             background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
//             content: " ";
//             position: absolute;
//             height: 100%;
//             width: 100%;
//             z-index: 5;
//         }
//         &.type-a {
//             .before {
//                 a { 
//                     color: #fff; 
//                     font-weight: 600;
//                     font-size: 21px;
//                     line-height: 145%;
//                     letter-spacing: 0.02em;
//                     svg {
//                         margin-left: 10px;
//                     }
//                 }
//             }
//             .content {
//                 > div {
//                     > *:first-child {
//                         width: 100%;
//                         max-width: 30%;
//                         @include mq(700px) {
//                             max-width: 100%;
//                             margin-bottom: 20px;
//                         }
//                     }
//                     > *:last-child {
//                         max-width: calc(70% - 50px);
//                         @include mq(700px) {
//                             max-width: 100%;
//                         }
//                     }
//                 }
//             }
//         }
//         &.type-b {
//             .content {
//                 > div {
//                     @include mq(700px) {
//                     }
//                     > *:last-child {
//                         width: 100%;
//                         max-width: 30%;
//                         @include mq(700px) {
//                             max-width: 100%;
//                         }
//                     }
//                     > *:first-child {
//                         max-width: calc(70% - 50px);
//                         @include mq(700px) {
//                             max-width: 100%;
//                             width: 100%;
//                         }
//                     }
//                 }
//             }
//         }
//         &.home {
//             min-height: 680px;
//             height: 55vh;
//             transition: $transition;
//             // &:before {
//             //     opacity: 1;
//             //     transition: $transition;
//             //     background-position: center;
//             //     background-size: cover;
//             //     height: 100%;
//             //     width: 100%;
//             //     content: " ";
//             //     top: 0;
//             //     left: 0;
//             //     position: absolute;
//             // }
//             .images {
//                 height: 100%;
//                 width: 100%;
//                 top: 0;
//                 left: 0;
//                 position: absolute;
//                 > div {
//                     transition: $transition;
//                     opacity: 0;
//                     position: absolute;
//                     background-position: center;
//                     background-size: cover;
//                     background-repeat: no-repeat;
//                     height: 100%;
//                     width: 100%;
//                     top: 0;
//                     left: 0;
//                 }
//             }
//             .before {
//                 .Button { font-size: 18px; }
//             }
//             .content {
//                 > div {
//                     padding: 0 60px;
//                     align-items: center;
//                     position: relative;
//                     > *:first-child, > *:last-child {
//                         max-width: calc(50% - 120px);
//                         padding-top: 35px;
//                         padding-bottom: 35px;
//                         position: relative;
//                         @include mq(915px) {
//                             padding: 0 0px;
//                             max-width: 100%;
//                             width: 100%;
//                             order: 1;
//                             top: 20px;
//                         }
//                     }
//                     > *:last-child {
//                         max-width: calc(50% - 180px);
//                         @include mq(915px) {
//                             padding: 0;
//                             max-width: 100%;
//                             margin-left: 0px;
//                             order: 2;
//                             width: 100%;
//                             margin-top: 20px;
//                         }
//                         margin-left: 60px;
//                         p {
//                             font-size: 18px;
//                             line-height: 145%;
//                             letter-spacing: 0.02em;
//                             @include mq(915px) {
//                                 font-size: 16px;
//                             }
//                         }
//                     }
//                     > .action { 
//                         width: 140px;
//                         height: 140px;
//                         background: url(/app/themes/lumber/static/gfx/fragment/header.content.action.svg) center center no-repeat;
//                         background-size: 100% 100%;
//                         padding: 25px;
//                         display: flex;
//                         flex-direction: column;
//                         align-content: center;
//                         justify-content: center;
//                         position: relative;
//                         top: 50px;
//                         z-index: 10;
//                         svg {
//                             display: block;
//                             margin: 10px auto 0;
//                         }
//                         font-weight: 600;
//                         font-size: 1em;
//                         line-height: 145%;
//                         text-align: center;
//                         letter-spacing: 0.02em;
//                         text-transform: uppercase;
//                         @include mq(915px) {
//                             order: 3;
//                             margin-left: auto;
//                             margin-right: auto;
//                             font-size: 14px;
//                         }
//                     }
//                 }
//             }
//         }
//         & + section {
//             padding-top: 150px;
//             // &:before { content: " "; display: block; padding-top: 3.5%; }
//         }
//         .before {
//             position: relative;
//             z-index: 10;
//             top: calc(10% - 30px);
//             text-align: right;
//         }
//         .content {
//             position: relative;
//             z-index: 10;
//             top: 10%;
//             background: c(main);
//             a {
//                 color: c(main);
//                 transition: $transition;
//                 svg {
//                     *[stroke='#D15E14'] {
//                         stroke: c(main);
//                         transition: $transition;
//                     }
//                 }
//                 &:hover {
//                     color: c(copy);
//                     svg {
//                         *[stroke='#D15E14'] {
//                             stroke: c(copy);
//                         }
//                     }
//                 }
//             }
//             h1, h2, h3, p, li {
//                 color: #fff;
//                 margin-bottom: 0;
//             }
//             h1, h2 {
//                 font-style: normal;
//                 font-weight: 400;
//                 font-size: 40px;
//                 line-height: 110%;
//                 @include mq(1250px) { font-size: 30px; }
//                 @include mq(915px) { font-size: 26px; }
//             }
//             > div {
//                 display: flex;
//                 padding: 35px 60px;
//                 justify-content: space-between;
//                 z-index: 10;
//                 @include mq(915px) {
//                     flex-wrap: wrap;
//                 }
//                 > * {
//                     width: 100%;
//                 }
//             }
//             &:before {
//                 content: " ";
//                 background: url(/app/themes/lumber/static/gfx/fragment/header.content.top.svg) top center;
//                 background-size: 100% 100%;
//                 background-repeat: no-repeat;
//                 height: 10px;
//                 width: 100%;
//                 display: block;
//                 position: absolute;
//                 top: -12px;
//                 z-index: 5;
//             }
//             &:after {
//                 z-index: 5;
//                 content: " ";
//                 background: url(/app/themes/lumber/static/gfx/fragment/header.content.bottom.svg) top center;
//                 background-size: 100% 100%;
//                 background-repeat: no-repeat;
//                 height: 10px;
//                 width: 100%;
//                 display: block;
//                 position: absolute;
//                 bottom: -12px;
//             }
//         }
//     }
// }

.glide {
    
}

.articles, .products {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 0px;
    padding: 0!important;
    list-style: none!important;
    @include bp(xs) {
        margin: 0 0;
    }
    > li {
        margin: 0 20px 40px;
        max-width: calc(33.333333% - 40px);
        @include bp(sm) {
            max-width: calc(50% - 25px);
        }
        @include bp(xs) {
            max-width: 100%;
            margin: 0 0 50px;
        }
        width: 100%;
        position: relative;
        .Button {
            margin-bottom: 10px;
        }
    }
    &.negative-margins {
        margin-left: -12px;
        margin-right: -12px;
        @include bp(xs) {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.products {
    > li {
        @include mq(700px) {
            max-width: calc(50% - 25px)!important;
            margin: 0 12px 50px!important;
            .actions {
                display: flex;
                flex-direction: column;
                align-items: center;
                .Button { margin-left: 0; }
            }
        }
    }
}

.filters-opened {
    .articles, .products {
        > li {
            max-width: calc(50% - 40px);
            @include bp(xs) {
                max-width: 100%;
                margin: 0 0 50px;
            }
        }
    }
    .products {
        > li {
            @include mq(700px) {
                max-width: calc(50% - 25px)!important;
                margin: 0 12px 50px!important;
            }
        }
    }
}

.article {
    height: 100%;
    .image {
        width: 100%;
        max-width: 100%;
        display: block;
        a {
            display: block;
        }
        img {
            display: block;
            border: 0;
        }
    }
    .informations {
        padding: 30px 0px;
        .categories {
            ul { padding: 0!important; margin: 0!important; }
            li {
                display: inline-block;
                margin: 0 15px 15px 0;
                padding: 6px 15px;
                border-radius: 6px;
                border: 1px solid c(fourth);
            }
        }
        .date {
            color: c(main);
            margin-bottom: 10px;
            font-style: italic;
        }
        .description {
            &, p, li {
                color: c(copy);
            }
            // min-height: 160px;
        }
        h2 {
            font-size: 21px;
            margin-bottom: 15px;
            a {
                color: c(copy);
            }
        }
        a {
            color: c(main);
        }
        .more {
            margin-top: 20px;;
        }
        .link {
            span {
                padding-right: 10px;
            }
        }
    }
    &.orange {
        .informations {
            background: c(main);
            h2, h3, h4, p, a {
                color: #fff!important;
            }



            .more {
                text-align: right;
                a {
                    .stroke { stroke: #fff; }

                }
            }
        }
    }
    &.transparent {
        position: relative;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        &:before {
            background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            content: " ";
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 5;
        }
        .image {
            display: none;
        }
        .informations {
            position: relative;
            z-index: 10;
            h2, h3, h4, p, a {
                color: #fff!important;
            }

            .more {
                text-align: right;
                a {
                    .stroke { stroke: #fff; }

                }
            }
        }
    }
    &.grey {
        .informations {
            background: #EFF3F2;
            h2, h3, h4, p, a {
                color: c(copylight)!important;
            }

            h2, h3, h4 {
                &, a {
                    color: c(copylight)!important;
                }
            }

            .more {
                text-align: right;
                a {
                    .stroke { stroke: c(copylight); }

                }
            }
        }
    }
}

.wpcf7-form {
    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .wpcf7-form-control {
        // color: #fff;
        background: transparent;
        border: 1px solid c(secondary);
        display: block;
        &:not(.wpcf7-submit) {
            width: calc(100% - 25px);
            padding: 5px 10px;
        }
        &:focus {
            outline: none;
        }
        &.wpcf7-radio, &.wpcf7-acceptance {
            border: 0;
            padding: 0;
        }
    }
    .wpcf7-form-control.wpcf7-submit {
        margin-top: 10px;
        background: #fff;
        color: c(main);
        border: none;
        &:hover {
            background: c(fourth);
            color: #fff;
        }
    }
    input[type="radio"], input[type="checkbox"] {
        appearance: none;
        -webkit-appearance: none;
        border: none;
        border-radius: 0px;
        position: relative;
    }
    
    input[type="radio"] {
        top: 4px;
        border-radius: 100%;
        width: 15px;
        height: 15px;
        border: 1px solid c(secondary);
        &:checked {
            background: c(secondary);
        }
    }

    .wpcf7-list-item {
        margin-left: 0px;
        display: block;
    }
    
    input[type="checkbox"] {
        top: 2px;
        width: 15px;
        height: 15px;
        // border: 1px solid c(secondary);
        &:checked {
            background: c(secondary);
        }
        & + span {
            display: inline-block;
            width: calc(100% - 40px);
            margin-left: 10px;
            vertical-align: top;
        }
    }

    .contact-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > div {
            width: 100%;
            max-width: calc(50% - 10px);
            margin-bottom: 20px;
            @include mq(500px) {
                max-width: 100%;
            }
            &:nth-child(3) {
                max-width: 100%;
            }
        }
    }
    .form-content {
        h3 {
            -webkit-text-stroke: 1px #fff;
            color: transparent;
            font-weight: bold;
            font-size: 30px;
            letter-spacing: 1px;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .informations {
            width: 100%;
            max-width: calc(100% - 310px);
            @include mq(850px) {
                max-width: 100%;
            }
            .fields {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-wrap: wrap;
                > div {
                    width: 100%;
                    max-width: calc(50% - 10px);
                    margin-bottom: 20px;
                    @include mq(500px) {
                        max-width: 100%;
                    }
                }
            }
            margin-bottom: 40px;
        }
        .cercle {
            width: 100%;
            max-width: 230px;
            @include mq(850px) {
                max-width: 100%;
            }
            margin-bottom: 40px;
            #cercle {
                > span {
                    display: block;
                    margin-left: 0;
                    label {
                        font-weight: normal;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .acceptance {
            margin-bottom: 30px;
        }
        .acceptance, .submit {
            width: 100%;
            label {
                font-weight: normal;
            }
        }
    }
}

.single-tribe_events {
    padding-top: 100px;
}