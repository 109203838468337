.products {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    &.columns-4 {
        > .product {
            max-width: calc(25% - 15px);
            margin-right: (4 * 15px) / 3;
            margin-bottom: (4 * 15px) / 3;
            &:nth-child(4n) {
                margin-right: 0;
            }
            @include bp(md) {
                max-width: calc(33% - 15px);;
                margin-right: (3 * 15px) / 2;
                margin-bottom: (3 * 15px) / 2;
                &:nth-child(4n) { margin-right: (3 * 15px) / 2; }
                &:nth-child(3n) { margin-right: 0; }
            }
            @include bp(sm) {
                max-width: calc(50% - 15px);;
                margin-right: 30px;
                margin-bottom: 30px;
                &:nth-child(4n) { margin-right: 30px; }
                &:nth-child(3n) { margin-right: 30px; }
                &:nth-child(2n) { margin-right: 0; }
            }
            @include bp(xs) {
                max-width: 100%;
                margin-right: 0;
                &:nth-child(4n) { margin-right: 0; }
                &:nth-child(3n) { margin-right: 0; }
                &:nth-child(2n) { margin-right: 0; }
            }
        }
    }
    &.columns-2 {
        > .product {
            max-width: calc(50% - 15px);;
            margin-right: 30px;
            margin-bottom: 30px;
            &:nth-child(2n) { margin-right: 0; }
            @include bp(xs) {
                max-width: 100%;
                margin-right: 0;
            }
        }
    }
    > .product {
        width: 100%;
        > a.woocommerce-loop-product__link {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            text-decoration: none;
            > img {
                max-width: 100%;
                height: auto;
                width: 100%;
                display: block;
                margin-bottom: 15px;
            }

            > h2 {
              font-size: 1.2em; 
              font-weight: normal;
            }
            > .price {
                margin-left: auto;
                text-decoration: none;
                del {
                    font-size: 0.7em;
                    margin-right: 10px;
                }
                ins {
                    text-decoration: none;
                }
            }
        }
        &.sale {
            > a.woocommerce-loop-product__link {
                > .onsale {
                    background: map-get($colors, main);
                    color: #fff;
                    position: absolute;
                    padding: 5px 10px;
                }
            }
        }
        > a.button {
            @include Button();
            display: block;
            text-align: center;
            
        }
        &.product-category {
            > a {
                > h2.woocommerce-loop-category__title {
                    font-size: 1.2em;
                    font-weight: normal;
                    > .count {
                        background: none;
                        font-size: 0.9em;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}