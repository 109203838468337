@keyframes revealLeftToRight {
    from { width: 0%; }
    to { width: 100%; }
}

.revealer {
    position: relative;
    overflow: hidden;
    &[data-theme="light"] {
        > .mask-reveal {
            background: map-get($grey, light);
        }
    }
    > .mask-reveal {
        background: darken(map-get($blues, dark), 3%);
        content: " ";
        display: block;
        width: 0%;
        height: 0%;
        position: absolute;
        z-index: 5;
        overflow: hidden;
    }
    > .content-reveal {
        height: 0%;
        width: 0%;
        overflow: hidden;
        z-index: 6;
        position: relative;
    }
    &, &[data-reveal="left-to-right"] {
        > .mask-reveal,
        > .content-reveal {
            width: 0%;
            height: 100%;
        }
        &.revealed {
            > .mask-reveal,
            > .content-reveal {
                animation-name: revealLeftToRight;
                animation-duration: 400ms;
                animation-fill-mode: forwards;
                animation-timing-function: ease-in-out;
            }
            > .content-reveal { animation-delay: 200ms; }
        }
    }
    &[data-reveal="right-to-left"] {
        > .mask-reveal,
        > .content-reveal {
            width: 0%;
            height: 100%;
            margin-left: 100%;
        }
    }
    &[data-reveal="center-to-side"] {
        > .mask-reveal {
            width: 0%;
            height: 100%;
            margin-left: 50%;
            margin-right: 50%;
        }
        > .content-reveal {
            width: 0%;
            height: 100%;
            margin-left: 50%;
            margin-right: 50%;
        }
    }
    &[data-reveal="top-to-bottom"] {
        > .mask-reveal {
            width: 100%;
            height: 0%;
        }
        > .content-reveal {
            width: 100%;
            height: 0%;
        }
    }
    &[data-reveal="bottom-to-top"] {
        > .mask-reveal {
            width: 100%;
            height: 0%;
            margin-top: 100%;
        }
        > .content-reveal {
            width: 100%;
            height: 0%;
            margin-top: 100%;
        }
    }
    &[data-reveal="center-to-top"] {
        > .mask-reveal {
            width: 100%;
            height: 0%;
            margin-top: 50%;
        }
        > .content-reveal {
            width: 100%;
            height: 0%;
            margin-top: 50%;
        }
    }
    &[data-reveal="all"] {
        > .mask-reveal {
            width: 0%;
            height: 0%;
            margin-top: 50%;
        }
        > .content-reveal {
            width: 0%;
            height: 0%;
            margin-top: 50%;
        }
    }
}