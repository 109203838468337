.Cart {

    $cartActiveColor : map-get($colors, main );

    position: relative;
    padding-left: 15px;
    // z-index: 1000;
    &.higlight {
        > a {
            > .count {
                background: map-get($colors, secondary);
            }
        }
    }
    > a {
        padding-bottom: 20px;
        position: relative;
        z-index: 10;
        display: flex;
        align-items: flex-end;
        text-decoration: none;
        color: #222;
        transition: $transition;
        &:hover {
            color: $cartActiveColor;
        }
        svg {
            position: relative;
            left: 5px;
            top: 10px;
        }
        > .count {
            transition: $transition;
            color: #222;
            background: #ffffff;
            width: 20px;
            height: 20px;
            line-height: 20px;
            border-radius: 50%;
            font-size: 10px;
            text-align: center;
            position: relative;
            top: 18px;
            left: -5px;
        }
    }
    > .data {
        position: absolute;
        z-index: 10;
        right: 0;
        width: 220px;
        max-width: 220px;
        padding: 30px 20px;
        box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
        display: none;
        background: #ffffff;
        .Item {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid map-get($grey, light);
            .informations {
                > div {
                    h3 { font-size: 1.2em; font-weight: normal; width: 100%;}
                    .price { font-size: 1em; width: 100%; }
                    .actions {
                        display: flex;
                        flex-wrap: wrap;
                        font-size: 0.8em;
                        justify-content: space-between;
                        > .rm {
                            a.rm {
                                color: map-get($colors, main);
                                text-decoration: none;
                                transition: $transition;
                                &:hover {
                                    color: map-get($colors, secondary);
                                }
                            }
                        }
                    }
                }
            }
        }
        .Button {
            @include Button();
            padding: 7px 10px;
            display: block;
            text-align: center;
        }

        .subtotal {
            margin-bottom: 10px;
            table {
                width: 100%;
                font-weight: bold;
                td:first-child {
                }
                td:last-child {
                    text-align: right;
                }
            }
        }
    }
    &:hover {
        > a {
            color: $cartActiveColor;
        }
        > .data {
            display: block;
        }
    }
}
