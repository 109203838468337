#main-content-wrapper .content > .builder-node:first-of-type {
    padding-top: 0px;
}
#main-content-wrapper .content > .builder-node:last-of-type {
    padding-bottom: 0px;
}

.builder-node {
    h2 {
        text-align: left;
    }
    &.alone, &.products-wrapper {
        
    }
    &.default-style + .builder-node.default-style {
        padding-top: 0px;
    }
    + .related.products {
        margin-top: 0!important;
    }

    p {
        color: c(copylight);
        font-size: 18px;
    }
    &.layout.grey {
        margin: 30px 0;
    }
    &.text {
    }
    // padding: 50px 0;
    // @include bp(sm) {
    //     padding: 30px 0;
    // }
    // &.slider:first-child {
    //     padding-top: 0;
    // }
    // &.slider:last-child {
    //     padding-bottom: 0;
    // }

    img {
        max-width: 100%;
        width: 100%;
        display: block;
    }
    // &.lightgreen {
    //     background: map-get($colors, third);
    // }
    // &.darkgreen {
    //     background: map-get($colors, secondary);
    //     h1, h2, h3, h4, h5, p, li {
    //         color: #fff;
    //     }
    //     h1, h2 {
            
    //         color: #fff;
    //     }
    //     .Button { @include Button("outline"); }
    // }
    // &.grey {
    //     background: #f7f7f7;
    // }
}

@import "./alone";
@import "./twins";
@import "./three-columns";
@import "./slider.scss";
