.builder-node.slider {
    > div {
        @include container();
        overflow: hidden;
        min-height: 50vh;
        border-radius: 40px 40px 0 0;
        margin-top: 25px;
    }
    .slideItem {
        min-height: 500px;
        height: 50vh;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        position: relative;
        .overlay {
            top: 0;
            left: 0;
            width: 100%;
            position: absolute;
            background: fade-out(map-get($colors, copy), 0.39);
            // background: map-get($colors, main);
            height: 100%;
            z-index: 3;
            mix-blend-mode: multiply;
        }
        .text {
            position: relative;
            z-index: 4;
            max-width: 460px;;
            width: calc(100% - 120px);
            margin-left: 150px;
            @include bp(xs) {
                margin-left: 30px;
            }
            padding: 30px 0;
            color: #fff;
            h1, h2, h3 { color: #fff; @include fontSize(2.2em); }
            .action {
                margin-top: 20px;
                font-size: 1em;
            }
            p {
                font-size: 1.25em;
                line-height: 1.65em;
            }
        }
    }
}
