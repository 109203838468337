#main-header {
    position: absolute;
    top: 0;
    z-index: 10000;
    left: 0;
    right: 0;
    background: c(main);
    > div {
        @include container();
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 0;
        > #logo {
            position: relative;
            z-index: 25;
            max-width: 150px;
            width: 100%;
            img {
                max-width: 150px;
                width: 150px;
                display: block;
            }
            .default-logo {
                svg {
                    max-height: 50px;
                }
            }
        }

        .language-switcher {
            text-transform: uppercase;
            a {
                font-weight: 300;
                color: c(copy);
                transition: $transition;
                span {
                    font-size: 12px;
                }
                &:hover {
                    color: c(main);
                }
            }
        }

        > #menu-switcher {
            display: none;
            @include bp(sm) {
                display: block;
                position: relative;
                z-index: 25;
                margin-left: auto;
                .bar { 
                    display: inline-block; position: relative; top: -4px; 
                    *[stroke="#000"] {
                        stroke: #ffffff;
                    }
                }
                .cross { display: none; }
                &.open {
                    .bar { display: none; }
                    .cross { display: inline-block; }
                    a {
                        // background: #000;
                    }
                }
                a {
                    margin-left: auto;
                    background: transparent;
                    transition: background 0.2s ease-in-out;
                    display: block;
                    text-align: center;
                    position: relative;
                }
            }
        }

        > #nav-main {
            width: 100%;
            max-width: calc(100% - 200px);
            margin-left: auto;
            @include bp(sm) {
                max-width: 100%;
            }
            text-align: right;
            position: relative;
            > ul {
                font-family: ff(copy);
                font-size: 16px;
                line-height: 120%;
                // text-transform: uppercase;
                @include bp(sm) {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 25px;
                }
                > li {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 15px;
                    &:first-child { margin-left: 0px; }
                    @include mq(1160px) {
                        margin-left: 10px;
                        &:first-child { margin-left: 0px; }
                    }
                    @include bp(sm) {
                        margin-left: 0px;
                    }

                    a {
                        display: block;
                        transition: 0.2s color ease-in;
                        text-decoration: none;
                        color: c(copy);
                        padding: 6px 15px;
                        font-weight: 300;
                        &:hover, &:focus {
                            color: c(secondary);
                        }
                    }
                    &.current-menu-item > a, &.is-active > a, &.current-menu-ancestor > a {
                        color: c(secondary);
                        pointer-events: none;
                    }
                    position: relative;
                    > .menuList {
                        display: none;
                        position: absolute;
                        background: #fff;
                        padding: 5px 15px 5px;
                        text-align: left;
                        width: calc(100% - 30px);
                        a {
                            padding: 3px 0px;
                        }
                    }

                    &:hover > .menuList {
                        display: block;
                    }

                }
            }
            @include bp(sm) {

                > ul { display: none; }
                &.show-menu {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    background: c(main) url(/app/themes/lumber/static/gfx/backgrounds/menu.mobile.png) right bottom no-repeat;
                    z-index: 20;
                    bottom: 0;
                    overflow: auto;
                    > ul {
                        @include container();
                        padding-top: 25vh;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        > li {
                            a {
                                // color: #341F25;
                                font-weight: 600;
                                font-size: 26px;
                                line-height: 110%;
                                transition: $transition;
                                font-family: ff(title);
                                &:hover {
                                    // color: c(main);
                                }
                            }
                            margin-bottom: 15px;
                            ul {                                
                                position: relative;
                                display: block;
                                padding-left: 60px;
                                a {
                                    // font-weight: 400;
                                }
                            }
                        }
                    }
                    .language-switcher-menu-item {
                        display: none;
                    }
                }
            }
        }
    }
}

#screen-language-switcher {
    position: fixed;
    display: none;
    z-index: 10000000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background: #fff;
    padding: 30px 0;
    > header {
        @include container();
        display: flex;
        justify-content: space-between;
        img {
            max-width: 120px;
        }
        .close {
            margin-top: 0px;
        }
    }
    > .content {
        @include container();
        min-height: calc(100vh - 210px);
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        padding-top: 70px;
        h3 {
            margin-bottom: 100px;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 42px;
            color: c(copy);
            @include bp(sm) {
                margin-bottom: 50px;
            }
        }
        ul {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: left;
            flex-wrap: wrap;
            &:after {
                content: " ";
            }
            > li, &:after {
                max-width: 22%;
                margin: 0 1.5% 40px;
                width: 100%;
                @include bp(md) {
                    max-width: 30%;
                }
                @include bp(sm) {
                    max-width: 47%;
                }
                @include bp(xs) {
                    transform: scale(0.8);
                }
            }
            > li {
                &.current {
                    > a > span > span.country { color: c(main); }
                }
                > a {
                    display: flex;
                    align-items: center;
                    &:hover {
                        > span > .country { color: c(main); }
                    }
                    > img {
                        max-width: 80px;
                        margin-right: 15px;;
                    }
                    > span {
                        > span {
                            display: block;
                            &.country {
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 27px;
                                color: #181818;
                                transition: $transition;
                            }
                            &.language {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 21px;
                                color: #808080;
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }
    }
}
