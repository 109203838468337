body {
    
}

.siema-slider-nav {
    .right svg {
        transform: rotate(180deg)!important;
    }
}

html {
    /* Adjust font size */
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    body {
        background: #114034;
        line-height: 1.55em;
        font-size: 16px;
        font-family: map-get($fonts, copy);
        color: c(copy);
        transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
        overflow-x: hidden;
        button, a {
            color: map-get($colors, main);
            text-decoration: none;
            .arrow {
                circle { fill: map-get($colors, main); transition: $transition; }
                path { stroke: #fff; transition: $transition; }
            }
            &:hover {
                color: map-get($colors, secondary);
                .arrow {
                    circle { fill: #fff; }
                    path { stroke: map-get($colors, main); }
                }
            }
        }

        strong { font-weight: 700; }
        em { font-style: italic; }

        h1, h2, h3, h4, h5, h6 {
            font-family: ff(program);
            font-weight: bold;
            text-align: left;
            color: #d3e3d2;
            text-transform: uppercase;
            a {
                color: map-get($colors, main);
                transition: 0.2s all ease-in;
                text-decoration: none;
                &:hover {
                    color: map-get($colors, secondary);
                }
            }
        }
        
        h1 { 
            font-size: 6em;
            line-height: 0.8em;
            @media screen and (max-width: 1000px) { font-size: 3.5em; }
            @media screen and (max-width: 500px) { font-size: 2.1em; }
        }
        
        h2 { 
            font-size: 5em;
            line-height: 0.8em;
            @media screen and (max-width: 1000px) { font-size: 3.5em; }
            @media screen and (max-width: 500px) { font-size: 2.1em; }
        }
        h3 { 
            font-size: 40px; 
            @media screen and (max-width: 1000px) { font-size: 1.8em; }
            @media screen and (max-width: 500px) { font-size: 1.6em; }
        }
        h4 { 
            font-size: 1.7em; 
            @media screen and (max-width: 1000px) { font-size: 1.6em; }
            @media screen and (max-width: 500px) { font-size: 1.4em; }
        }
        h5 { 
            font-size: 1.5em; 
            @media screen and (max-width: 1000px) { font-size: 1.4em; }
            @media screen and (max-width: 500px) { font-size: 1.3em; }
        }
        h6 { 
            font-size: 1.3em; 
            @media screen and (max-width: 1000px) { font-size: 1.2em; }
            @media screen and (max-width: 500px) { font-size: 1.2em; }
        }

        .apply-effect {
            
        }
        
        img {
            max-width: 100%;
        }

        .glide-overlap {
            overflow: hidden;
            .glide__track {
                overflow: visible;
            }
        }
        .glide-overlap-on-mobile {
            @include bp(xs) {
                overflow: hidden;
                .glide__track {
                    overflow: visible;
                }
            }
        }

    }
} 

// CSS HELPERS

.swn-wtb-btn {
    border-radius: 25px 0 0 25px!important;
    .swn-lp-cart {
        font-size: 28px;
    }
    span {
        font-size: 16px;
        text-transform: uppercase;
        font-family: ff(title);
        font-weight: normal;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
    }
}

.upper { text-transform: uppercase; }
.text-right { text-align: right; }
.text-center { text-align: center; }
.container, .c { @include container(); &.m-900 { max-width: 900px; } }
.wpcf7-form-control.wpcf7-submit, .Button:not(.menu-item) { 
    @include Button();
    &.outline { @include Button("outline"); }
    &.white { @include Button("white"); }
    &.upper { text-transform: uppercase; }
    & + .Button { margin-left: 15px; }
}


.arrow-svg {
    &.right { transform: rotate(180deg); }
    &.left { transform: rotate(0deg); }
    &.top { transform: rotate(90deg); }
    &.bottom { transform: rotate(-90deg); }
    .stroke {
      transition: $transition;
    }
}

p + p.ButtonWrapper {
    margin-top: 50px;
}

.carouselWrapper {
    &.has-next > .glide > .controls > .glide__arrow.glide__arrow--next { opacity: 1; pointer-events: initial; }
    &.has-prev > .glide > .controls > .glide__arrow.glide__arrow--prev { opacity: 1; pointer-events: initial; }

    &[data-small-control] {
        > .glide > .controls > .glide__arrow {
            svg { height: 65px; width: 65px; }
            &.glide__arrow--prev { left: -30px; }
            &.glide__arrow--next { right: -30px; }
        }
    }
    &[data-no-control] {
        > .glide > .controls {
            display: none;
        }
    }
    &[data-arrow-narrow] {
        > .glide > .controls > .glide__arrow {
            &.glide__arrow--prev { left: -50px; }
            &.glide__arrow--next { right: -50px; }
        }
    }
    @include mq(1250px) {
        > .glide > .controls > .glide__arrow {
            svg { height: 65px!important; width: 65px!important }
            &.glide__arrow--prev { left: -60px!important; }
            &.glide__arrow--next { right: -60px!important; }
        }
    }
    @include mq(750px) {
        > .glide > .controls > .glide__arrow {
            display: none;
        }
    }

    .glide .controls {
        .carousel__arrow {
            
        }
    }

    &[data-white] {
        .glide__bullets {
            .glide__bullet {
                &--active {
                    background: c(main);
                    outline: 1px solid c(main);
                    opacity: 1;
                }
            }   
        }
    }
    .glide__bullets {
        width: 100%;
        padding-top: 50px;
        text-align: center;
        position: relative;
        bottom: 0;
        left: 0;
        justify-content: center;
        margin: 0 auto;
        transform: none;
        .glide__bullet {

            height: 10px;
            width: 10px;
            box-shadow: none;
            background: transparent;
            opacity: 1;
            outline: 1px solid c(main);
            &:hover {
                background: c(main);
                border: none;
            }
            &--active {
                background: #fff;
                outline: 1px solid #fff;
                opacity: 1;
            }
        }
    }
    > .glide {
        position: relative;
        > .controls {
            .glide__arrow {
                // top: auto;
                // bottom: 30px;
                opacity: 0;
                border: 0;
                padding: 0;
                pointer-events: none;
                box-shadow: none;
                top: calc(50% - 32px);
                &.glide__arrow--next { right: 0px; svg { display: block; transform: rotate(-180deg); transform-origin: center center; } }
                // svg { height: 100px; width: 100px; }
                // &.glide__arrow--prev { left: -80px; }
                // &.glide__arrow--next { right: -80px; }
            }
        }
    } 
}

.duo {
    & + .duo { 
        margin-top: 50px;
        @include mq(700px) {
            margin-top: 30px;
        }
    }
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    &.aic { align-items: center; }
    &.rr { flex-direction: row-reverse; }
    &.rrom {
        @include mq(700px) {
            flex-direction: row-reverse;
        }
    }
    &.srom {
        @include mq(700px){
            flex-direction: row;
        }
    }
    > * {
        width: 100%;
        max-width: calc(50% - 30px);
    }
    &.large-spacing {
        > * {
            width: 100%;
            max-width: calc(50% - 60px);
        }
    }

    @include mq(700px) {
        flex-direction: column;
        > * {
            max-width: 100%;
            &:nth-child(2) { margin-top: 30px; }
        }
        &.large-spacing {
            > * {
                max-width: 100%;
                &:nth-child(2) { margin-top: 30px; }
            }
        }
    }
}

.breadcrumb {
    padding-top: 60px;
    margin-right: 30px;
    margin-bottom: 40px;
    > ul {
        display: flex;
        align-items: center;
        list-style: none!important;
        padding-left: 0!important;
        @include bp(xs) {
            flex-wrap: wrap;
        }
        > li {
            opacity: 1;
            font-weight: normal;
            font-size: 16px;
            line-height: 145%;
            a {
                .arrow { position: relative; top: -2px; margin-right: 4px; }
                .arrow path { stroke: c(copy); transition: $transition; }
                color: c(copy)!important;
                transition: $transition;
                &:hover {
                    color: c(main)!important;
                    .arrow path { stroke: c(main); }
                }
            }
            &:after {
                // width: 15px;
                background: #000;
                background: transparent;
                color: c(copy);
                height: auto;
                content: "|";
                display: inline-block;
                vertical-align: top;
                margin: 0 3px;
            }
            &:last-child {
                opacity: 1;
                a {
                    pointer-events: none;
                    color: c(main)!important;
                }
            }
            &:last-child:after {
                display: none;
            }
        }
    }
}

iframe {
    max-width: 100%;
}

.transition-screen {
    width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
    z-index: -5;
    opacity: 0;
    &.active {
        z-index: 100;
        opacity: 1;
    }
    cursor: pointer;
    > svg {
        height: 100%;
        width: 100%;
    }
}

.text-section {
    &.intro {
        max-width: 650px;
        margin: 0 auto;
        h1, h2 {
            margin-bottom: 45px;
        }
        p {
            font-size: 24px;
            color: #53565A;
            line-height: 145%;
            @include mq(1250px) { font-size: 21px; }
            @include mq(915px) { font-size: 16px; }
        }
        & + .ButtonWrapper {
            margin-top: 60px;;
        }
    }
}

.default-padding {
    padding: 200px 0;
    @include bp(xs) {
        padding: 50px 0;
    }
    
    &.no-padding-top { padding-top: 0px; }
    &.no-padding-bottom { padding-bottom: 0px; }
}

.no-padding-top {
    padding-top: 0px;
}

.default-small-padding {
    padding: 50px 0;
    @include bp(xs) {
        padding: 25px 0;
    }

    &.no-padding-top { padding-top: 0px; }
    &.no-padding-bottom { padding-bottom: 0px; }
    &.default + .default-small-padding.default {
        padding-top: 0px;;
    }
}

.layout {
    position: relative;
    z-index: 6;
    & + * {
        position: relative;
        z-index: 5;
    }
    &.has-top:before {
        z-index: 10;
        content: " ";
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: top center;
        height: 10px;
        width: 100%;
        display: block;
        position: absolute;
        top: -12px;
    }
    &.has-bottom:after {
        z-index: 10;
        content: " ";
        background-position: bottom center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 9px;
        width: 100%;
        display: block;
        position: absolute;
        bottom: -12px;
    }
    &.grey { 
        padding: 60px 0!important;
        background: map-get($blues, transparent);
        * { color: #000; }
        &.has-bottom:after { background-image: url(/app/themes/lumber/static/gfx/fragment/layout.grey.bottom.svg); }
        &.has-top:before { background-image: url(/app/themes/lumber/static/gfx/fragment/layout.grey.top.svg); }
    }
    &.orange { 
        background: c(main); 
        &.has-bottom:after { background-image: url(/app/themes/lumber/static/gfx/fragment/layout.orange.bottom.svg); }
        &.has-top:before { background-image: url(/app/themes/lumber/static/gfx/fragment/layout.orange.top.svg); }
        h1, h2, h3, h4, p, li, a {
            color: #fff;
        }
        svg.full path { fill: #fff; }
        svg.empty path { fill: darken(c(main), 10%); }
        a {
            color: #fff;
            &:hover {
                color: c(copy);
            }
        }
    }
    &.has-top-pattern {
        background-image: url(/app/themes/lumber/static/gfx/backgrounds/pattern.top.png);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    &.nform {
        // background-image: 
        //     url(/app/themes/lumber/static/gfx/backgrounds/nform.top.left.png),
        //     url(/app/themes/lumber/static/gfx/backgrounds/nform.bottom.left.png),
        //     url(/app/themes/lumber/static/gfx/backgrounds/nform.bottom.right.png),
        //     url(/app/themes/lumber/static/gfx/backgrounds/nform.top.right.png)
        // ;
        // background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
        // background-position: 0px 50px, 0px 350px,  100% 50px, 100% 350px;
        background-image: 
            url(/app/themes/lumber/static/gfx/pola.left.png),
            url(/app/themes/lumber/static/gfx/pola.right.png)
        ;
        background-repeat: no-repeat, no-repeat;
        background-position: 10% 50%, 90% 50%;
        background-size: 300px;
        @include mq(1520px) { background-position: 5% 50%, 95% 50%; }
        @include mq(1400px) { background-position: 0% 50%, 100% 50%; }
        @include mq(1280px) { background-position: -10% 50%, 110% 50%; }
        @include mq(1180px) { background-position: -5% 50%, 105% 50%; background-size: 200px;}
        @include mq(750px) { background-image: none; }
    }
}

.large-bottom-padding {
    padding-bottom: 200px;
}

.background {
    &.blf {
        background-image: url(/app/themes/lumber/static/gfx/backgrounds/food.bottom.left.jpg);
        background-position: bottom left;
        background-repeat: no-repeat;
    }
    &.brf {
        background-image: url(/app/themes/lumber/static/gfx/backgrounds/food.bottom.right.jpg);
        background-position: bottom right;
        background-repeat: no-repeat;
    }
}

.header {
    &.section {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include mq(750px) {
            flex-wrap: wrap;
            > * {
                width: 100%;
                max-width: 100%;
            }
            h2 {
                margin-bottom: 40px;
                text-align: center;
            }
            .ButtonWrapper {
                text-align: center;
                display: none;
            }
        }
    }
}

.columns {
    &.by-three {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        &:after {
            content: " ";
            width: 100%;
            max-width: calc(33% - 40px);
        }
        > div {
            width: 100%;
            max-width: calc(33% - 40px);
        }
        &.large-spacing {
            > div, &:after { max-width: calc(33% - 75px); }
        }
        @include bp(sm) {
            > div {
                max-width: 100%;
                margin-bottom: 50px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
    &.has-icon {
        .image {
            img { display: block; margin: 0 auto; }
            margin-bottom: 25px;;
        }
    }
}

.sharer {
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;
    .title {
        margin-right: 15px;
        margin-bottom: 5px;
        // color: c(main);
        text-align: right;
        // font-weight: bold;
    }
    .actions {
        display: flex;
        margin-bottom: 20px;
        .shared-item {
            margin-bottom: 10px;
            display: inline-block;
            height: 50px;
            width: 50px;
            margin-right: 15px;
            line-height: 54px;
            text-align: center;
            border: 1px solid c(main);
            transition: $transition;
            border-radius: 50px;
            &:hover {
                background: c(main);
                border-color: c(main);
                svg *[fill="#fff"] { fill: #fff; }
            }
            svg {
                *[fill="#fff"] {
                    fill: c(main);
                    transition: $transition;
                }
                height: 15px;
                width: 15px;
            }
        }
    }
}

.items-system {
    > .c {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > .header {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 70px;
            > .search-form {
                margin-left: 30px;
                width: 50%;
                display: flex;
                form {
                    width: 100%;
                    display: flex;
                }
                &.full {
                    width: calc(100% - 230px);
                    @include bp(xs) {
                        width: calc(100% - 130px);
                    }
                }
                &.alone {
                    width: 100%;
                    margin-left: 0;
                }
                svg {
                    margin-right: 15px;
                }
                input {
                    display: block;
                    border: 0;
                    border-bottom: 1px solid fade-out(c(copy), 0.7);
                    transition: $transition;
                    width: calc(100% - 35px);
                    &:focus {
                        outline: none;
                        box-shadow: none;
                        border-color: c(copy);
                    }
                }
            }
            > .activate-filter {
                width: 200px;
                @include mq(700px) {
                    max-width: 180px;
                }
                svg {
                    circle {
                        transition: $transition;
                    }
                }
                .show { display: flex; }
                .hide { display: none; }
                font-weight: 600;
                font-size: 18px;
                line-height: 145%;
                text-align: center;
                text-transform: uppercase;
                color: c(copy);
                @include bp(xs) {
                    font-size: 14px;
                    max-width: 120px;
                    text-align: left;
                    > span > span {
                        text-align: left;
                        position: relative;
                        top: -15px;
                    }
                }
                svg {
                    margin-right: 10px;
                }
                a {
                    color: c(copy);
                    transition: $transition;
                    svg { path, circle { stroke: c(copy); transition: $transition; }}
                    &:hover {
                        color: c(main);
                        svg { path, circle { stroke: c(main); }}
                    }
                }
            }
        }
        > .filters {
            width: 100%;
            max-width: calc(33% - 55px);
            display: none;
            border-right: 1px solid fade-out(#341F25, 0.7);
            padding-right: 30px;
            @include mq(700px) {
                max-width: 100%;
                border-right: 0;
                padding-right: 0px;
                padding-bottom: 1px solid fade-out(#341F25, 0.7);
                margin-bottom: 30px;
            }
            h3 {
                margin-bottom: 20px;
                font-weight: 600;
                font-size: 26px;
                line-height: 110%;
            }
            ul {
                margin-bottom: 40px;
                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    flex-wrap: wrap;
                    > ul {
                        margin-top: 10px;
                        width: calc(100% - 35px);
                        margin-left: 35px;
                        margin-bottom: 20px;
                    }
                    input[type="checkbox"] {
                        appearance: none;
                        -webkit-appearance: none;
                        border-radius: 0;
                        border: 1px solid c(main);
                        border-radius: 5px;
                        background: #fff;
                        height: 20px;
                        width: 20px;
                        &:checked {
                            background: c(main);
                        }
                    }
                    label {
                        font-size: 18px;
                        line-height: 145%;
                        letter-spacing: 0.02em;
                        color: #341F25;
                        opacity: 0.7;
                        margin-left: 15px;
                        width: calc(100% - 50px);
                    }
                }
            }

        }
        > .items {
            width: 100%;
        }
    }
    &.filters-opened {
        > .c {
            > .header {
                > .activate-filter {
                    svg {
                        circle {
                            cy: 16;
                        }
                    }
                    .show { display: none; }
                    .hide { display: flex; }
                }
            }
            > .filters {
                display: block;
            }
            > .items {
                max-width: calc(67% - 25px);
                @include mq(700px) {
                    max-width: 100%;
                }
            }
        }
    }
}

#naturalform {


    &[data-type="cat"] { .dog:not(.cat) {display: none!important; } }
    &[data-type="dog"] { .cat:not(.dog) {display: none!important; } }
    // min-height: 450px;
    > h2 {
        font-weight: 600;
        font-size: 50px;
        line-height: 110%;
        text-align: center;
        margin-bottom: 40px;
        @include mq(1250px) { font-size: 30px; }
        @include mq(915px) { font-size: 26px; }
    }
    .steps.overview {
        min-height: 40px;
        margin-top: 40px;
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
        ul > li {
            margin: 0 25px 20px;
            > strong { 
                display: inline-block; vertical-align: middle; 
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 110%;
                color: #53565A;
            }
            > span { 
                margin-left: 5px;
                display: inline-block; vertical-align: middle; 
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 110%;
                color: c(main);
            }
        }
    }
    .steps {
        > li {
            .action {
                font-size: 1.2em;
                margin-top: 50px;
            }
            text-align: center;
            display: none;
            &[data-step="1"] {
                display: block;
            }
            .header.section {
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 50px;
                .hint {
                    width: 100%;
                    max-width: 100%;
                    color: c(main);
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 110%;
                    margin-top: 15px;
                }
                .back {
                    margin: 0;
                }
                h2 {
                    font-family: ff(copy);
                    font-style: normal;
                    font-weight: 300;
                    font-size: 28px;
                    line-height: 110%;
                    color: #341F25;
                    margin: 0;
                    @include mq(1250px) { font-size: 24px; }
                    @include mq(915px) { font-size: 21px; }
                }
            }
            input[type="radio"], input[type="checkbox"] {
                appearance: none;
                -webkit-appearance: none;
                border-radius: none;
                border: 0;
                box-shadow: none;
                background-color: transparent;
                & + label {
                    @extend .Button.outline;
                    padding: 8px 30px;
                    margin-bottom: 15px;
                    margin-right: 10px;
                    &:hover {
                        span { background: transparent!important; }
                        color: c(main);
                    }
                }
            }
            input[type="checkbox"]:checked + label {
                background-color: c(main)!important;
                border-color: c(main)!important;
                color: #fff!important;
                span { color: #fff!important; }
            }

            label.checked {
                background-color: c(main)!important;
                border-color: c(main)!important;
                color: #fff!important;
                span { color: #fff!important; }
            }
        }
    }
}

.newsletter-block {
    form {
        @include container();
        max-width: 700px;
        > .row {
            display: flex;
            align-items: center;
            @include mq(700px) {
                flex-wrap: wrap;
            }
            > h2 {
                font-weight: 600;
                font-size: 37px;
                line-height: 120%;
                max-width: 50%;
                width: 100%;
                margin: 0;
                @include mq(1250px) { font-size: 30px; }
                @include mq(915px) { font-size: 26px; }
                @include mq(700px) {
                    max-width: 100%;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }
            > .form-group {
                max-width: 50%;
                width: 100%;
                display: flex;
                align-items: center;
                border: 1px solid #fff;
                border-radius: 70px;
                height: 70px;
                @include mq(700px) {
                    max-width: 100%;
                    height: 50px;
                }
                input {
                    line-height: 70px;
                    width: calc(100% - 52px);
                    background: none;
                    border:0!important;
                    box-shadow: none!important;
                    outline: 0!important;
                    text-indent: 20px;
                    color: #fff;
                    font-size: 1.2em;
                    @include mq(700px) {
                        line-height: 50px;
                    }
                }
                button {
                    width: 70px;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: transparent;
                    border: 1px solid #fff;
                    border-radius: 100%;
                    position: relative;
                    left: 1px;
                    @include mq(700px) {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
    }
}

.pagination {
    text-align: center;
    margin-top: 100px;
}

.page-numbers {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: c(copy);
    opacity: 0.55;
    transition: $transition;
    &.prev, &.next {
        @extend .Button;
    }
    &.prev {
        float: left;
    }
    &.next {
        float: right;
    }
    &.current, &.current:hover {
        color: #D3E3D2;
        opacity: 1;
    }
    &:hover {
        opacity: 1;
        color: #fff;
    }
}

.accordion {
    margin-top: 30px;
    margin-bottom: 50px;
    &.product {
        > li {
            > .header {
                h2 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: 300;
                    font-size: 35px;
                    line-height: 140%;
                    a {
                        color: c(main);
                        text-decoration: underline;
                        font-size: 18px;
                        line-height: 145%;
                        letter-spacing: 0.02em;
                        transition: $transition;
                        &:hover {
                            color: c(copy);
                        }
                    }
                }
            }
            > .content {
                padding-left: 90px;
                width: calc(80% - 93px);
            }
        }
    }
    > li {
        padding: 0 0 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid fade-out(c(copy), 0.7);
        &.open {
            > .header {
                .more {
                    .bar {
                        display: none;
                    }
                }
            }
        }
        > .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                margin: 0;
                width: calc(100% - 90px);
            }
            .more {
                width: 60px;
            }
        }
        > .content {
            display: none;
            padding-left: 30px;
            width: calc(80% - 33px);
            border-left: 3px solid c(main);
            margin-top: 30px;
            p {
                font-weight: normal;
                font-size: 18px;
                line-height: 145%;
                /* or 26px */
                letter-spacing: 0.02em;
                margin-bottom: 25px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            ul {
                margin-bottom: 25px;
                li {
                    margin-bottom: 10px;
                }
                &:last-child {
                    margin-bottom: 0px;
                } 
            }
        }
    }
}

.category {
    > .title {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        > span {
            color: c(main);
            font-weight: 300;
            font-size: 21px;
            line-height: 140%;
            margin-left: 10px;
        }
    }
}

.breeder-content {
    background: c(main);
    color: #fff;
    img {
        width: 100%;
    }
    .text, .informations {
        padding: 40px;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;

        /* or 31px */

        /* Wellness/light-grey */
        color: #EFF3F2;
        .name {
            font-weight: 300;
            font-size: 16px;
            line-height: 140%;
            margin-top: 25px;
            color: #EFF3F2;
        }
    }
}


// @import "./helpers/ihavecookies";
a.Button, input.Button, input[type='submit'], button.Button {
    background: transparent;
    cursor: pointer;
    @include Button();
    &.outline {
        @include Button("outline");
        &.white {
            border-color: #fff;
            color: #fff;
            &:hover {
                background: #fff;
                color: map-get($blues, dark);
            }
        }
    }
}

.tile {
    position: relative;
    z-index: 8;
    > * {
        position: relative;
        z-index: 8;
    }
    h2 {
        position: relative;
        top: -20px;
        left: -20px;
    }
    p {
        padding-left: 90px;
        padding-right: 90px;
        margin-bottom: 25px;
        @include bp(xs) {
            padding-left: 20px;
            padding-right: 20px;
        }
        &:last-child {
            margin-bottom: none;
        }
    }
    &:before {
        z-index: 6;
        width: calc(100% - 60px);
        @include bp(xs) {
            width: calc(100% + 40px);
            left: -20px;
        }
        left: 30px;
        height: calc(100% + 30px);
        position: absolute;
        background: #144b3d;
        opacity: 0.85;
        box-shadow: 0px 60px 99px rgba(0, 0, 0, 0.3);
        content: " ";
        display: block;
    }
}