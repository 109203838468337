.tease-container {
    .tease {    
        .categories {
            > ul {
                padding: 0!important; margin: 0!important;
                list-style: none!important;
                > li {
                    display: inline-block;
                    margin-right: 10px;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 1.1em;
                }
            }
        }
        .picture {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: map-get($grey, light);
            img {
                max-width: 100%;
                visibility: hidden;
                margin: 0;
            }
            a {
                display: block;
            }
        }
        .date {
            margin: 15px 0 0;
            text-transform: uppercase;
            font-size: 0.9em;
        }
        h2 {
            margin-top: 20px;
            font-size: 1.15em;
            letter-spacing: 1px;
        }

        .actions {
            display: flex;
            justify-content: space-between;
            > span {
                font-size: 0.8em;
                a {
                    color: map-get($grey, dark)!important;
                    font-weight: bold;
                    text-transform: uppercase;
                    &:hover, &:focus {
                        color: map-get($colors, main)!important;
                    }
                    img {
                        max-width: 15px;
                    }
                }
                &:last-child {
                    font-size: 1.15em;
                    > a {
                        margin-left: 10px;
                    }
                }
            }
        }

        &.first {
            h2 {
                font-size: 1.75em;
                @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                    font-size: 1.25em;
                }
            }
        }

        margin-bottom: 30px;
    }   
}