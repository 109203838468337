.builder-node.alone {
    > div {
        @include container();
        .builder-node {
            padding: 25px 0;
            &:first-child { padding-top: 0; }
            &:last-child { padding-bottom: 0; }
            &.button {
                text-align: center;
            }

            h2, h3 {
                margin-bottom: 30px;
            }
        }
    }
}
