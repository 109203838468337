.page-template-tpl-trails {
    main {
        background-position: 0 -15%;
        background-size: 100%;
        background-repeat: no-repeat;
        > header {
            min-height: 30vh;
            padding-top: 200px;
            > .container {
                text-align: center;
                max-width: 950px;
                .content {
                    h1 {
                        margin-bottom: 30px;
                        text-align: center;
                        margin-top: 30px;
                    }
                    .ButtonWrapper {
                        margin-top: 40px;
                    }
                }
            }
        }

        #trails {
            background: url(/app/themes/lumber/static/gfx/triangle.bottom.png) bottom center no-repeat;
            background-size: 900px;
            padding: 150px 0;
            @include bp(xs) {
                padding: 75px 0;
            }
            .items {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                &:hover {
                    > li {
                        opacity: 0.4;
                    }
                }
                > li {
                    transition: $transition;
                    &:hover {
                        opacity: 1;
                    }
                    background: #144b3d;
                    box-shadow: 0px 60px 99px rgba(0, 0, 0, 0.16);
                    width: 100%;
                    max-width: calc(50% - 15px);
                    @include bp(sm) {
                        max-width: 100%;
                    }
                    margin-bottom: 30px;
                    .content {
                        padding: 30px 55px;
                        @include bp(xs) {
                            padding: 30px;
                        }
                        h2 {
                            @include bp(xs) {
                                font-size: 1.4em;
                                max-width: 100%;
                            }
                            font-size: 1.75em;
                            margin-bottom: 25px;;
                            max-width: 75%;
                            line-height: 1.25em;
                            a {
                                color: map-get($colors, secondary);
                            }
                        }
                        .icons {
                            display: flex;
                            svg {
                                width: 50px;
                                height: 50px;
                                display: inline-block;
                                vertical-align: middle;
                                & + span {
                                    vertical-align: middle;
                                    display: inline-block;
                                    font-size: 20px;
                                    line-height: 30px;
                                    text-align: left;
                                    color: #fff;
                                    margin-left: 10px;
                                    @include bp(xs) {
                                        font-size: 14px;
                                        white-space: nowrap;
                                    }
                                }
                            }
                            li {
                                margin-right: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

}


.single-trail {
    main {

        #map-holder {
            #map {
                min-height: 400px;
                height: 60vh;
                .gm-style .gm-style-iw-t::after {
                    background: linear-gradient(45deg,c(main) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%);
                }
                .gm-style-iw.gm-style-iw-c {
                    border-radius: 0;
                    background: c(main);
                    padding: 0;
                    max-width: 300px;
                    .gm-ui-hover-effect {
                        background: c(third)!important;
                        color: #fff;
                        top: 0px!important;
                        right: 0px!important;
                    }
                    .pop-up-map {
                        display: flex;
                        align-items: stretch;
                        .image {
                            max-width: 25%;
                            overflow: hidden;
                        }
                        img {
                            max-width: 100%;
                            display: block;
                        }
                        .content {
                            overflow: auto;
                            padding: 25px;
                            h2, h3 {
                                font-size: 1.45em;
                                margin-bottom: 15px;
                            }
                            p { margin-bottom: 25px; }
                        }
                    }
                }
            }
        }

        #content {
            > .container {
                max-width: 1100px;
                padding: 100px 0;
                color: #A6D1A9;
                .place {
                    list-style: circle;
                    h3 {
                        font-family: ff(copy);
                        font-weight: normal;
                        line-height: 30px;
                        font-size: 20px;
                    }
                    color: #A6D1A9;
                }
                h2 {
                    color: c(third);
                    font-family: ff(title);
                    font-size: 2em;
                    text-transform: none;
                    font-weight: none;
                    margin-bottom: 35px;
                }
                p {
                    margin-bottom: 25px;
                }

                .action {
                    margin-top: 50px;
                }
            }
        }
        header {
            min-height: 50vh;
            padding: 120px 0;
            position: relative;
            display: flex;
            align-items: center;
            &:before {
                background: linear-gradient(#114034 0%, rgba(17, 64, 52, 0.29) 100%);
                content: " ";
                height: 100%;
                width: 100%;
                top: 0;
                display: block;
                position: absolute;
                z-index: 4;
            }
            > * {
                position: relative;
                z-index: 6;
            }
            .content {
                max-width: 950px;
                text-align: center;
                h1 {
                    text-align: center;
                }
            }
            .icons {
                margin-top: 50px;
                display: flex;
                justify-content: center;
                svg {
                    width: 50px;
                    height: 50px;
                    display: inline-block;
                    vertical-align: middle;
                    & + span {
                        vertical-align: middle;
                        display: inline-block;
                        font-size: 20px;
                        line-height: 30px;
                        text-align: left;
                        color: #fff;
                        margin-left: 10px;
                    }
                }
                li {
                    margin-right: 30px;
                }
            }
        }
    }   
}