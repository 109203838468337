.single-product {
    --plyr-color-main: #D15E14;
    > .swn-wtb-btn {
        display: none;
    }
    main {
        > .header {
            position: relative;
            > .background {
                position: absolute;
                height: calc(100% - 80px);
                width: 100%;
                background-image: url(/app/themes/lumber/static/gfx/backgrounds/product.header.png);
                background-position: 115% -180px;
                background-repeat: no-repeat;
                background-size: 40%;
            }
            > .c {
                position: relative;
                z-index: 10;
                .carouselWrapper .glide__bullets {
                    padding-top: 10px;
                }
                .gallery-item {
                    a {
                        img {
                            opacity: 1;
                            transition: $transition;
                        }
                        &:hover img {
                            opacity: 0.9;
                        }
                    }
                    img {
                        display: block;
                        max-width: 100%;
                        margin: 0 auto;
                    }
                    .video {
                        position: relative;
                        display: block;
                        img {
                            position: relative;
                        }
                        &:before {
                            content: " ";
                            height: 75px;
                            width: 75px;
                            position: absolute;
                            background: url(/app/themes/lumber/static/gfx/play.svg) center center no-repeat;
                            top: calc(50% - 38px);
                            left: calc(50% - 38px);
                            display: block;
                            z-index: 10;
                        }
                    }
                }
                h1 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 35px;
                    line-height: 120%;
                    margin-bottom: 50px;
                    &.has-note {
                        margin-bottom: 20px;
                    }
                }
                .note {
                    display: flex;
                    margin-bottom: 50px;
                    .ranking { margin-right: 20px;}
                    a { color: c(copy); text-decoration: underline; &:hover { color: c(main); }}
                }

                .benefit {
                    display: flex;
                    align-items: flex-start;
                    > .image {max-width: 100px; width: 100%; margin-right: 30px; }
                    > .text { 
                        max-width: calc(100% - 200px); width: 100%; 
                        h2, h3 {
                            font-weight: 600;
                            font-size: 25px;
                            line-height: 120%;
                            margin: 0;
                            margin-bottom: 15px;
                        }
                        p, li {
                            font-size: 18px;
                            line-height: 145%;
                            letter-spacing: 0.02em;
                            opacity: 0.7;
                        }
                    }
                }
            }
            margin-bottom: 50px;
        }
        > #content {
            .content {
                .text {
                    opacity: 0.7;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 145%;
                    p {
                        margin-bottom: 25px;
                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }
                }
                .actions {
                    text-align: center;
                }
            }
            .benefits {
                margin-top: 80px;
                .column {
                    margin-bottom: 50px;
                    font-weight: bold;
                    letter-spacing: 0.02em;
                    line-height: 145%;
                    .text {
                        max-width: 250px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    img {
                        max-width: 120px;
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
        }
        > #ingredients {
            .header {
                h1, h2, h3 {
                    margin-bottom: 40px;
                }
            }
            .columns {
                .image {
                    max-width: 270px;
                    margin: 0 auto 10px;
                    img {
                        display: block;
                        margin: 0 auto;
                    }
                }
                .text {
                    text-align: center;
                    h3 {}
                    p {
                        font-size: 18px;
                        line-height: 145%;
                        text-align: center;
                        letter-spacing: 0.02em;
                        opacity: 0.7;
                    }
                }
            }
        }

        > #reviews {
            text-align: center;
            .header {
                justify-content: center;
                align-items: center;
                h2 {
                    margin: 0;
                    margin-bottom: 25px;
                    text-align: center;
                    width: 100%;
                }
            }
            .review-item {
                max-width: 600px;
                margin: 0 auto;
                text-align: center;
                .image {
                    width: 120px;
                    border-radius: 100%;
                    overflow: hidden;
                    display: block;
                    margin: 0 auto;
                    img {
                        max-width: 100%;
                    }
                }
                .ranking {
                    margin: 17px 0;
                    text-align: center;
                }
                .content {
                    p, li {
                        font-size: 18px;
                        line-height: 145%;
                        text-align: center;
                        letter-spacing: 0.02em;
                    }
                    opacity: 0.7;
                    margin-bottom: 15px;
                }
                .name {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 140%;
                    text-align: center;
                }
            }
        }
    }
}
