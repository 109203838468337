.woocommerce-checkout.woocommerce-page {
    padding-top: 150px;
    main {
        > section {
            @include container();
            .woocommerce {
                > .woocommerce-form-coupon-toggle {
                    margin-bottom: 30px;
                }

                > .checkout_coupon.woocommerce-form-coupon {
                    background: map-get($grey, light);
                    padding: 25px 25px;
                    margin-bottom: 30px;
                    > .form-row {
                        margin-top: 15px;
                        display: inline-block;
                        > input {
                            padding: 10px 15px;
                            border: 1px solid map-get($grey, medium);
                            border-right: 0px;
                            &:focus {
                                box-shadow: none;
                                outline: none;
                                border-color: map-get($colors, main);
                            }
                        }
                        > .button {
                            @include Button();
                        }
                    }
                }

                > form {
                    > #customer_details {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        > div {
                            width: 100%;
                            max-width: calc(50% - 100px);
                            .woocommerce-billing-fields__field-wrapper,
                            .woocommerce-shipping-fields__field-wrapper {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                > * {
                                    width: 100%;
                                    max-width: 100%;
                                }
                                #billing_first_name_field,
                                #billing_last_name_field,
                                #shipping_first_name_field,
                                #shipping_last_name_field {
                                    max-width: calc(50% - 20px);
                                }
                            }
                            .woocommerce-billing-fields, 
                            .woocommerce-additional-fields, 
                            .woocommerce-shipping-fields {
                                > div > div {
                                    display: flex;
                                    flex-wrap: wrap;
                                    > p {
                                        width: 100%;
                                        max-width: 100%;
                                    }
                                }
                                p {
                                    margin-bottom: 15px;
                                    label {
                                        display: block;
                                    }
                                    .woocommerce-input-wrapper > strong,
                                    input[type=text], 
                                    input[type=number], 
                                    input[type=phone], 
                                    input[type=tel], 
                                    input[type=password], 
                                    input[type=email], 
                                    select, 
                                    textarea {
                                        font-size: 0.9em;
                                        font-family: map-get($fonts, copy);
                                        display: block;
                                        width: calc(100% - 20px);
                                        padding: 4px 10px;
                                        border: 1px solid map-get($grey, medium);
                                        &:focus {
                                            outline: none;
                                            box-shadow: none;
                                        }
                                    }
                                    .woocommerce-input-wrapper > strong {
                                        padding: 3px 10px;
                                    }
                                    
                                    &.woocommerce-invalid {
                                        .woocommerce-input-wrapper > strong,
                                        input[type=text], 
                                        input[type=number], 
                                        input[type=phone], 
                                        input[type=tel], 
                                        input[type=password], 
                                        input[type=email], 
                                        select, 
                                        textarea {
                                            border-color: map-get($stateColors, error);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                    > h3 {
                        margin-top: 35px;
                    }

                    > #order_review {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: flex-start;
                        .shop_table {
                            width: 100%;
                            max-width: calc(40% - 60px);
                            padding: 20px 30px;
                            width: 100%;

                            text-align: left;
                            border-collapse: collapse; 
                            td, th {
                                padding: 5px 20px;
                                border: 1px solid map-get($grey, light);
                            }
                            td:first-child, th:first-child {
                                background: map-get($grey, light);
                            }
                            .woocommerce-shipping-totals.shipping {
                                td, th {
                                    border-bottom-color: map-get($colors, main);
                                }
                            }
                            .order-total {
                                th, td {
                                    color: map-get($colors, main);
                                    border-color: map-get($colors, main);
                                    font-weight: bold;
                                }
                                th:first-child {
                                    background: map-get($colors, main);
                                    color: #fff;
                                }
                            }

                            // background: map-get($colors, main);
                            // color: #fff;
                            // font-weight: bold;
                            // thead {
                            //     th {
                            //         text-align: left;
                            //         padding-top: 20px;
                            //         padding-bottom: 10px;
                            //         font-weight: bold;
                            //     }
                            //     th:first-child {
                            //         padding-left: 30px;
                            //     }
                            //     th:last-child {
                            //         padding-right: 30px;
                            //     }
                            // }
                            // tfoot {
                            //     text-align: left;
                            //     th {
                            //         font-weight: bold;
                            //     }
                            //     th:first-child {
                            //         padding-left: 30px;
                            //     }
                            //     td:last-child {
                            //         padding-right: 30px;
                            //     }

                            //     th, td {
                            //         padding: 5px 0;
                            //         border-top: 1px solid #fff;
                            //     }
                                
                            //     tr.order-total {
                            //         th, td {
                            //             padding-bottom: 20px;
                            //         }
                            //     }
                            // }
                            // tbody {
                            //     > .cart_item {
                            //         td {
                            //             padding-bottom: 10px;
                            //             padding-top: 10px;
                            //             border-top: 1px solid #fff;
                            //         }
                            //         &:last-child {
                            //             td {
                            //                 padding-bottom: 10px;
                            //             }
                            //         }
                            //         td {
                            //             text-align: left;
                            //         }
                            //         td:first-child {
                            //             padding-left: 30px;
                            //         }
                            //         td:last-child {
                            //             padding-right: 30px;
                            //         }
                            //     }
                            // }
                        }
                        > #payment {
                            width: 100%;
                            max-width: 60%;
                            .methods {
                                li {
                                    margin-bottom: 10px;
                                    div {
                                        margin-top: 10px;
                                        background: map-get($grey, light );
                                        padding: 8px 15px;
                                    }
                                }
                            }
                            .button {
                                @include Button();
                                font-size: 1em;
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
