.page-template-tpl-project {
    main {
        background-position: 0 -10%;
        background-size: 100%;
        background-repeat: no-repeat;
        @include bp(md) {
            background-position: 0 -3%;
        }
        > header {
            min-height: 30vh;
            padding-top: 200px;
            @include bp(md) {
                padding-bottom: 0px;
                min-height: auto;
            }
            @include bp(sm) {
                padding-bottom: 80px;
                min-height: auto;
            }
            > .container {
                text-align: center;
                .content {
                    h1 {
                        margin-bottom: 30px;
                        text-align: center;
                        margin-top: 30px;
                    }
                    p {
                        max-width: 350px;
                    }
                    .ButtonWrapper {
                        margin-top: 40px;
                    }
                }
            }
        }
    }

    #blocks {
        background-position: center center;
        background-size: contain;
        position: relative;
        .backgrounds {
            position: absolute;
            z-index: 1;
            top: 0px;
            left: 0;
            height: 100%;
            width: 100%;
            display: block;
            .star {
                background: url(/app/themes/lumber/static/gfx/star.svg) center left no-repeat;
                background-size: contain;
                position: absolute;
                width: 80px;
                height: 80px;
                top: 30%;
                left: 30%;
                &.star-1 {
                    top: 35%;
                    left: 30%;
                }
                &.star-2 {
                    top: 70%;
                    left: 70%;
                }
            }
            .triangle {
                position: absolute;
                width: 25%;
                max-width: 300px;
                height: 50%;
                &.left { 
                    left: 0%; top: 15%; 
                    background: url(/app/themes/lumber/static/gfx/triangle.left.png) center left no-repeat;
                    background-size: contain;
                }
                &.right { 
                    right: 0%; top: 65%; 
                    background: url(/app/themes/lumber/static/gfx/triangle.right.png) center right no-repeat;
                    background-size: contain;
                }
            }
        }
        .container {
            position: relative;
            z-index: 2;
            max-width: 1100px;
        }
        .row {
            min-height: 75vh;
            @include bp(md) {
                min-height: 650px;
            }
            display: flex;
            align-items: center;
            .image {
                max-width: 50%;
                img {
                    position: relative;
                }
            }
            &.row-1, &.row-3 {
                > .content {
                    @extend .tile;
                }
            }
            &.row-1 {
                .image {
                    position: relative;
                    z-index: 2;
                    left: -70px;
                    top: 80px;
                }
                @include mq(940px) {
                    > .content {
                        max-width: 65%;
                    }
                    > .image {
                        max-width: 35%;
                        img {
                            max-width: 140%;
                            width: 140%;
                            left: -40%;
                            top: 150px;
                        }
                    }
                }
                @include bp(sm) {
                    flex-wrap: wrap;
                    > .content {
                        max-width: 90%;
                    }
                    > .image {
                        max-width: 60%;
                        margin-left: auto;
                        img {
                            left: 0%;
                            top: -100px;
                        }
                    }
                }
                @include bp(xs) {
                    > .content {
                        max-width: 100%;
                    }
                }
            }
            &.row-2 {
                justify-content: right;
                > .content {
                    @include bp(md) {
                        max-width: 65%;
                    }
                }
                @include bp(xs) {
                    > .content {
                        margin-top: 150px;
                        max-width: 100%;
                    }
                }
            }
            &.row-3 {
                justify-content: center;
                > .content {
                    @include bp(md) {
                        max-width: 65%;
                    }
                    @include bp(sm) {
                        max-width: 90%;
                    }
                }
                @include bp(xs) {
                    > .content {
                        max-width: 100%;
                    }
                }
            }
            > .content {
                width: 100%;
                max-width: 50%;
                h2 {
                    margin-bottom: 30px;
                }
                p {
                    margin-bottom: 25px;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    #partners {
        position: relative;
        top: -200px;
        @include bp(xs) {
            top: -100px;
        }
    }

    #partners, #clients {
        .text {
            margin-bottom: 60px;
        }
    }

    #alps {
        min-height: 100vh;
        padding-top: 150px;
        background-position: center bottom;
        @include bp(xs) {
            min-height: calc(100vh - 150px);
        }
        > .container {
            max-width: 950px;
            text-align: center;
            h2 {
                margin-bottom: 30px;
                text-align: center;
            }
            .ButtonWrapper {
                margin-top: 30px;
            }
        }
    }

    #contact {
        min-height: calc(100vh - 150px);
        padding-top: 150px;
        @include bp(md) {
            padding-top: 0px;
        }
        @include bp(sm) {
            min-height: calc(100vh - 250px);
        }
        background-position: center top;
        > .container {
            max-width: 950px;
            text-align: center;
            h2 {
                margin-bottom: 30px;
                text-align: center;
            }
            .ButtonWrapper {
                margin-top: 30px;
                @include bp(xs) {
                    background: map-get($colors, main);
                }
            }
        }
    }
}
