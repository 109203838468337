.has-bg {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.page-template-tpl-home {
    main {
        overflow-y: hidden;
        > header {
            min-height: 100vh;
            padding-top: 150px;
            position: relative;
            @include bp(xs) {
                background: #114034!important;
                min-height: auto;
            }
            > .container {
                @extend .duo;
                align-items: center;
                position: relative;
                z-index: 2;
                .content {
                    h1 {
                        margin-bottom: 30px;
                    }
                    p {
                        max-width: 350px;
                    }
                    .ButtonWrapper {
                        margin-top: 40px;
                    }
                    @include mq(700px) {
                        order: 1;
                        p {
                            max-width: 100%;
                        }
                    }
                }
                .image {
                    @include mq(700px) {
                        order: 2;
                        max-width: 70%;
                        display: none;
                    }
                }
            }
            > .triangle {
                position: absolute;
                top: 0px;
                right: 0;
                height: 100%;
                width: 100%;
                background: url(/app/themes/lumber/static/gfx/triangle.right.png) bottom right no-repeat;
                background-size: contain;
                z-index: 1;
                @include mq(1000px) {
                    height: 70%;
                    top: 7%;
                }
                @include mq(700px) {
                    height: 40%;
                    top: 40%;
                }
                @include mq(600px) {
                    height: 40%;
                    top: 10%;
                }
            }
        }
        > #what {
            @include mq(700px) {
                padding-top: 60px;
            }
            > .container {
                display: flex;
                flex-wrap: wrap;
                > .image {
                    width: 100%;
                    max-width: 32.5%;
                    position: relative;
                    z-index: 4;
                    &.left {
                        img {
                            position: relative;
                            top: -60px;
                            width: 120%;
                            max-width: 120%;
                        }
                        @include mq(700px) {
                            max-width: 60%;
                            margin-left: 15%;;
                            img {
                                top: 40px;
                                width: 180%;
                                max-width: 180%;
                            }
                        }
                    }
                    &.right {
                        max-width: 22.5%;
                        @include bp(md) {
                            position: absolute;
                            max-width: 30%;
                            margin-left: auto;
                            right: 30px;
                        }
                        @include mq(700px) {
                            max-width: 50%;
                            width: 50%;
                        }
                        @include mq(600px) {
                            max-width: 50%;
                            width: 50%;
                            position: relative;
                        }
                        img {
                            max-width: 110%;
                            width: 110%;
                            position: relative;
                            left: -50px;
                            top: 40px;
                            @include bp(md) {
                                top: -100px;
                            }
                            @include mq(700px) {
                                top: -150px;
                            }
                        }
                        align-self: flex-end;
                    }
                }
                > .content {
                    width: 100%;
                    max-width: 45%;
                    @extend .tile;
                    @include bp(md) {
                        max-width: 67.5%;
                    }
                    @include mq(700px) {
                        max-width: 90%;
                        position: relative;
                        top: -100px;
                    }
                }
                > .information {
                    width: 100%;
                    padding: 250px 0 50px;
                    text-align: center;
                    font-family: ff(title);
                    font-weight: 200;
                    color: c(third);
                    font-size: 2.5em;
                    line-height: 1.5em;
                    font-style: italic;
                    max-width: 980px;
                    margin: 0 auto;
                    @include bp(md) {
                        margin-top: 100px;
                    }
                    @include bp(xs) {
                        padding-top: 0px;
                        margin-top: 0px;
                        margin-bottom: 150px;;
                        font-size: 1.75em;
                    }
                }
            }
        }
        > #for_who {
            min-height: 100vh;
            background-position: top center;
            > .content {
                max-width: 950px;
                text-align: center;
                h2 {
                    background: url(/app/themes/lumber/static/gfx/star.svg) center left no-repeat, url(/app/themes/lumber/static/gfx/star.svg) center right no-repeat;
                    background-size: 60px, 60px;
                    padding: 0 95px;
                    display: inline-block;
                    margin-bottom: 30px;
                }
            }
            #products {
                margin-top: 50vh;
                @include bp(md) {
                    margin-top: 0vh;
                }
                position: relative;
                top: 150px;
                .container {
                    max-width: 1200px;
                }
            }
            .slider {
                .product-item {
                    background: #144b3d url(/app/themes/lumber/static/gfx/triangle.right.png) center right no-repeat;
                    background-size: auto 100%;
                    opacity: 0.95;
                    box-shadow: 0px 60px 99px rgba(0, 0, 0, 0.3);
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    @include mq(900px) {
                        background: #144b3d url(/app/themes/lumber/static/gfx/triangle.top.png) center top no-repeat;
                        background-size: 70% auto;
                    }
                    > .content {
                        padding: 50px;
                        width: 100%;
                        max-width: 50%;
                        @include mq(900px) {
                            max-width: 100%;
                            order: 3;
                        }
                        @include bp(xs) {
                            padding: 50px 25px;
                        }
                        h2 {
                            em {
                                font-family: ff(title);
                                text-transform: none;
                                font-weight: normal;
                                color: #A6D1A9;
                                font-size: 0.4em;
                            }
                            margin-bottom: 30px;
                        }
                        .ButtonWrapper {
                            margin-top: 30px;
                        }
                    }
                    > .image {
                        max-width: 50%;
                        width: 100%;
                        text-align: right;
                        
                        img {
                            display: block;
                            margin-left: auto;
                        }
                        @include mq(900px) {
                            padding: 30px 0;
                            max-width: 100%;
                            img {
                                margin: 0 auto;
                                max-width: 80%;
                                max-height: 250px;
                            }
                        }
                    }
                }
            }
        }
        > #news {
            > .container {
                max-width: 1000px;
                padding: 145px 0;
                > .text {
                    text-align: left;
                    margin-bottom: 45px;
                }
                > ul {
                    > li {
                        margin-bottom: 25px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        position: relative;
                        background: #144b3d;
                        box-shadow: 0px 60px 99px rgba(0, 0, 0, 0.16);
                        color: #D3E3D2;
                        &.featured {
                            background: #A6D1A9; 
                            &:before {
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 10;
                                background: url(/app/themes/lumber/static/gfx/article_highlight.svg) top left no-repeat;
                                background-size: 50px;
                                width: 50px;
                                height: 50px;
                                content: " ";
                            }
                            color: #114034;
                            h3 {
                                color: #114034;
                            }
                        }
                        display: flex;
                        align-items: stretch;
                        flex-wrap: wrap;
                        > .image {
                            background-position: center center;
                            background-size: cover;
                            background-repeat: no-repeat;
                            width: 25%;
                            @include mq(600px) {
                                width: 100%;
                                height: 180px;
                            }
                            img {
                                visibility: hidden;
                                max-width: 100%;
                                display: none;
                            }
                        }
                        > .content {
                            width: calc(75% - 80px);
                            padding: 40px;
                            display: flex;
                            @include mq(600px) {
                                width: calc(100% - 80px);
                            }
                            > .text {
                                h3 {
                                    font-family: ff(title);
                                    text-transform: none;
                                    margin-bottom: 15px;
                                    font-weight: normal;
                                    font-size: 1.75em;
                                }
                                max-width: 70%;
                            }
                        }
                    }
                }
            }
        }
        > #trail {
            background-position: top center;
            min-height: calc(130vh - 250px);
            padding-top: 250px;
            display: flex;
            align-items: center;
            @include bp(xs) {
                background-image: none;
                padding-top: 350px;
            }
            > .container {
                max-width: 900px;
                text-align: center;
                h2 {
                    margin: 30px 0;
                    text-align: center;
                }
                p {
                    margin-bottom: 30px;
                }
                .ButtonWrapper {
                    margin-bottom: 0px;
                }
            }
        }
        > #partners {
            background: #D3E3D2;
            padding: 40px 0;
            > .container {
                max-width: 90%;
                text-align: center;
                ul {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    > li {
                        margin: 25px 35px;
                        img {
                            max-height: 100px;
                            max-width: 160px;
                        }
                    }
                }
            }
        }
        > #contact {
            min-height: calc(100vh - 150px);
            padding-top: 150px;
            background-position: center top;
            @include bp(xs) {
                padding-top: 30px;
            }
            > .container {
                max-width: 950px;
                text-align: center;
                h2 {
                    margin-bottom: 30px;
                    text-align: center;
                }
                .ButtonWrapper {
                    margin-top: 30px;
                    .Button {
                        @include bp(xs) {
                            background: map-get($colors, main);
                        }
                    }
                }
            }
        }
    }
}
