.builder-node.three-columns {
    &.contained {
        > div {
            @include container();
        }
    }
    > div {
        .builder-node {
            padding: 15px 0;
            &:first-child { padding-top: 0; }
            &:last-child { padding-bottom: 0; }
        }
        
    }
}
