#footer {
    background: c(main) url(/app/themes/lumber/static/gfx/footer.background.jpg) center top no-repeat;
    background-size: cover;
    // margin-top: 80px;
    .social-network {
        text-align: left;
        @include bp(sm) {
            text-align: right;
        }
        @include bp(xs) {
            text-align: left;
        }
        padding: 0;
        margin-top: 20px;
        > li {
            display: inline-block;
            margin-right: 10px;
            @include bp(xs) {
                margin-right: 10px;
                margin-left: 10px;
            }
            > a {
                transition: 0.2s all ease-in;
                text-decoration: none;
                color: #fff;
                svg {
                    max-height: 20px;
                    *[fill="#53565A"] {
                        fill: #fff;
                        transition: $transition;
                    }
                }
                &:hover *[fill="#53565A"] {
                    fill: c(third);
                }
            }
        }
    }
    > div {
        &.top {
            padding: 100px 0 50px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            > div {
                width: 100%;
                max-width: 30%;
                &:nth-child(1) {
                    img {
                        margin-bottom: 15px;
                    }
                }
                &:nth-child(3) {
                    a { 
                        transition: $transition; 
                        font-size: 16px; 
                        color: #fff; 
                        &:hover { 
                            color: c(secondary); 
                        }
                    }
                }
                &:nth-child(1) { max-width: 280px; }
                &:nth-child(2), &:nth-child(3){
                    max-width: calc(40% - 180px);
                }
                color: #fff;

                &:nth-child(2) { margin-left: auto; }

                &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                    margin-bottom: 50px;
                }
                @include bp(lg) {
                    &:nth-child(1), &:nth-child(4) { max-width: 250px; }
                    &:nth-child(2), &:nth-child(3), &:nth-child(5), &:nth-child(6) {
                        max-width: calc(50% - 140px);
                    }
                }

                @include bp(md) {
                    &:nth-child(1), &:nth-child(4),
                    &:nth-child(2), &:nth-child(3), &:nth-child(5), &:nth-child(6) {
                        max-width: 50%;
                        margin-top: 40px;
                        margin-bottom: 0;
                    }
                    &:nth-child(1) {order: 5; img { max-width: 120px; }}
                    &:nth-child(2) {order: 1; margin-top: 0; }
                    &:nth-child(3) {order: 2; margin-top: 0; }
                    &:nth-child(4) {order: 6;}
                    &:nth-child(5) {order: 3;}
                    &:nth-child(6) {order: 4;}
                }
                @include bp(sm) {
                    &:nth-child(5), &:nth-child(6) {
                        max-width: 100%;
                    }
                    &:nth-child(6) {
                        .form-control {
                            background: map-get($colors, main)!important;
                            color: #fff!important;
                            border:0px!important;
                            box-shadow: none!important;
                            outline: 1px solid #fff!important;
                            text-transform: uppercase!important;
                            border-radius: 0px!important;
                            padding: 11.5px 10px!important;
                            display: block!important;
                            height: auto!important;
                        }
                        .col-sm-6.first_col {
                            padding-left: 0px;
                        }
                        .col-sm-6.last_col {
                            padding-right: 0px;
                        }
                    }
                }
                @include bp(xs) {
                    &:nth-child(2), &:nth-child(3) {
                        max-width: 100%;
                        width: 100%;
                    }
                    &:nth-child(3) {
                        margin-top: 40px;
                    }
                    &:nth-child(1) {
                        width: 100%;
                        max-width: 100%;
                    }
                    width: 100%!important;
                    max-width: 100%!important;
                }

                p {
                    margin-bottom: 25px;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
                h3 {
                    color: #A6D1A9;
                    font-size: 24px;
                    font-style: italic;
                    font-family: ff(title);
                    text-transform: none;
                    margin: 0;
                    margin-bottom: 16px;
                }
                nav {
                    > ul {
                        > li {
                            margin-bottom: 5px;
                            &:last-child {
                                margin-bottom: 0px;
                            }
                            > a {
                                color: #fff;
                                transition: $transition;
                                font-size: 18px;
                                &:hover { 
                                    color: map-get($colors, secondary);
                                }
                            }
                        }
                    }
                }
                #newsletter {
                    margin-top: 35px;
                    input[type="email"] {
                        background: none;
                        border: none;
                        color: c(third);
                        border-bottom: 1px solid c(third);
                        display: block;
                        width: 200px;
                        margin-bottom: 20px;
                        padding: 8px 0;
                    }
                }
            }
            .custom-logo {
                max-width: 150px;
                width: 100%;
                height: auto;
            }
        }

        &.bottom {
            // border-top: 1px solid map-get($colors, third);
            background: c(main);
            padding: 15px 0;
            text-align: center;
            font-weight: 300;
            letter-spacing: 1px;
            font-size: 0.9em;
            color: #fff;
            a {
                transition: $transition;
                color: #fff;
                &:hover {
                    color: map-get($colors, secondary);
                }
            }
            ul {
                display: inline-block;
                > li { 
                    display: inline-block;
                    &:last-child {
                        &:after { display: none; }
                    }
                    &:after {
                        content: "-";
                        display: inline-block;
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }
            }
            .copyright {
                display: inline-block;
            }
        }
        
    }
}
