.woocommerce-tabs {
    width: 100%;
    margin-top: 30px;
    > .tabs {
        background: map-get($grey, light);
        > li {
            display: inline-block;
            vertical-align: middle;
            > a {
                display: block;
                padding: 7px 20px;
                color: map-get($colors, secondary);
                text-decoration: none;
                transition: $transition;
                &:hover, &:focus {
                    background: map-get($colors, secondary);
                    color: #ffffff;
                }
            }
            &.active {
                > a {
                    background: map-get($colors, main);
                    color: #ffffff;
                }
            }
        }
    }

    > div {
        padding-top: 30px;
    }
}
