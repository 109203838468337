.woocommerce-page.woocommerce-order-received {
    padding-top: 150px;
    main {
        > section {
            @include container();
            .woocommerce {
                > .woocommerce-order {
                    > .woocommerce-notice {
                        background: map-get($grey, light);
                        padding: 10px 25px;
                        margin-top: 30px;
                        margin-bottom: 15px;
                        &.woocommerce-notice--success.woocommerce-thankyou-order-received {
                            margin-bottom: 15px;
                        }
                    }
                    > .woocommerce-order-overview {
                        > li {
                            font-size: 1.1em;
                            &.order {
                                strong {
                                    padding: 3px 5px;
                                    display: inline-block;
                                    background: map-get($colors, main);
                                    color: #fff;
                                }
                            }
                            strong {
                                font-weight: bold;
                            }
                            margin-bottom: 5px;
                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                        margin-top: 30px;
                        margin-bottom: 80px;
                    }

                    > .woocommerce-order-details {
                        margin-bottom: 80px;
                        table.shop_table {
                            width: 100%;
                            max-width: 500px;
                            text-align: left;
                            border-collapse: separate; 
                            td, th {
                                padding: 5px 20px;
                                border: 1px solid map-get($grey, light);
                                border-bottom: none;
                            }
                            td:first-child, th:first-child {
                                background: map-get($grey, light);
                            }
                            .woocommerce-shipping-totals.shipping {
                                td, th {
                                    border-bottom-color: map-get($colors, main);
                                }
                            }
                            tfoot tr:last-child {
                                th, td {
                                    color: map-get($colors, main);
                                    border-color: map-get($colors, main);
                                    font-weight: bold;
                                    border-bottom: 1px solid map-get($colors, main);
                                }
                                th:first-child {
                                    background: map-get($colors, main);
                                    color: #fff;
                                }
                            }
                        }
                    }
                    > .woocommerce-customer-details {
                        > .woocommerce-columns--addresses {
                            margin-bottom: 30px;
                            display: flex;
                            flex-wrap: wrap;
                            > div {
                                width: 100%;
                                max-width: 30%;
                                margin-right: 10%;
                            }
                        }
                    }
                }
            }
        }
    }
}
