.blog {
    padding-top: 150px;
    background: #fff url(/app/themes/lumber/static/gfx/blog.top.svg) top right no-repeat;
}

.home.blog, .blog, .single, .page-template-default, .singular, .page-template-default, .archive.category {
    background: c(main);
    // h1 { color: map-get($blues, dark); }
    
    .header.fragment {
        .date {
            font-weight: normal;
            font-size: 18px;
            line-height: 145%;
            letter-spacing: 0.02em;
            color: #EFF3F2;
        }
    }
    .header-action {
        display: flex;
        justify-content: space-between;
        @include mq(700px) {
            flex-wrap: wrap;
            .sharer {
                width: 100%;
                margin-bottom: 30px;
            }
            .breadcrumb {
                margin-bottom: 0px;
            }
        }
        .sharer {
            .Button {
                font-size: 1.2em;
                svg {
                    margin-left: 3px;
                    position: relative;
                    top: 5px;
                }
            }
        }
        .breadcrumb {
            padding-top: 15px;
        }
    }

    #main-content-wrapper {
        position: relative;
        > .actions {
            position: relative;
            > .sharer {
                position: absolute;
                right: 0;
                top: 0;
            }

        }
        hr {
            border: 0;
            border-bottom: 1px solid map-get($grey, regular);
            // margin: 60px 0 30px;
            display: block;
            padding-top: 40px;
            margin-bottom: 60px;
        }

        
        > h1 {
            text-align: center;
        }
        // padding: 50px 0 0;
        // .container {
        //     max-width: $container;
        //     width: 95%;
        //     margin: 0 auto;
        // }
        strong {
            color: map-get($colors, main);
            font-weight: bold;
        }
        ul {
            list-style-type: disc;
            list-style-position: outside;
            padding: 0 0 0 40px;
            margin-bottom: 1.35em;
            li { margin-bottom: 0.65em;}
        }
        ol {
            list-style-type: decimal;
            list-style-position: outside;
            padding: 0 0 0 40px;
            margin-bottom: 1.35em;
            li { margin-bottom: 0.65em;}
        }
        p {
            margin-bottom: 1.35em;
        }
        a {
            color: map-get($colors, main);
            transition: 0.2s all ease-in;
            &:hover {
                color: map-get($colors, secondary);
            }
        }
    }
    
    .categories-wrapper {
        padding-bottom: 35px;
        ul {
            list-style: none!important;
            padding: 0!important;
            margin: 0!important;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            li {
                margin: 5px 10px 5px 0px;
            }
        }
        a {
            padding: 3px 10px;
            border: 1px solid c(secondary);
            display: inline-block;
            background: transparent;
            transition: $transition;
            font-size: 0.9em;
            cursor: pointer;
            &:hover {
                background: c(main);
                border-color: c(main);
                color: #fff!important;
            }
            &.current {
                background: c(main);
                border-color: c(main);
                color: #fff!important;
                &:after {
                    transition: $transition;
                    content: "x";
                    width: 10px;
                    display: inline-block;
                    margin-left: 10px;
                    opacity: 1;
                }
            }
        }
    }

    .next {
        text-align: center;
        a {
            display: inline-block;
            text-align: center;
            display: inline-block;
            color: c(main);
            text-transform: uppercase;
            font-size: 0.7em;
            font-weight: bold;
            margin-bottom: 50px;
            svg {
                display: block;
                margin: 0px auto;
            }
        }
    }
    
}

.tool-pagination {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    > .prev, > .next {
        font-size: 1.45em;
        position: relative;
        top: 2px;
    }
    > .prev {
        margin-right: 15px;
    }
    > .pages {
        padding: 0!important;
        margin: 10px 0;
        list-style: none;
        display: inline-block;
        > li {
            display: inline-block;
            padding: 0 5px;
            > a {
                text-decoration: none;
            }
        }
    }
    > .next {
        margin-left: 15px;
    }
}

.post-template-default.single.single-post {
    padding-top: 150px;
    background: #fff url(/app/themes/lumber/static/gfx/blog.top.svg) top right no-repeat;
    .header-content {
        h1 {
            text-align: center;
            // color: #0A1946;
            margin-bottom: 10px;
        }
        .credits {
            text-align: center;
            em {
                font-style: normal;
                color: c(main);
            }
            margin-bottom: 30px;
        }
        margin-bottom: 40px;
    }
    #main-content-wrapper {
        .picture {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: map-get($grey, light);
            img {
                display: block;
            }
            margin-bottom: 40px;
        }
        .text {
            
        }
        > .content {
            padding-bottom: 100px;
            @include bp(sm) {
                padding-bottom: 50px;
            }
            @include mq(1030px) {
                padding-right: 60px;
            }
            img {
                height: auto;
            }
            > header {
                h1 {
                    margin-bottom: 5px;
                }
                .date {
                    color: c(secondary);
                    font-style: italic;
                    margin-bottom: 25px;;
                }
            }
            > .categories {
                text-align: left;
                font-size: 1.15em;
                margin-bottom: 0;
                color: c(main);
                > ul {
                    padding: 0!important; margin: 0!important;
                    list-style: none!important;
                    display: inline-block;
                    vertical-align: top;
                    > li {
                        display: inline-block;
                        margin-right: 10px;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 1em;
                        letter-spacing: 1px;
                    }
                }
                > .date {
                    display: inline-block;
                    vertical-align: top;
                    text-transform: uppercase;
                    &:before {
                        content: " | ";
                        margin-right: 5px;
                    }
                }
            }
            > h1 {
                text-align: center;
                margin-bottom: 30px;
                @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                    font-size: 1.75em;
                }
            }
        }
        > .actions {
            text-align: right;
            margin-bottom: 15px;
            font-size: 1.35em;
            > span {
                > a {
                    color: map-get($grey, dark);
                    margin-left: 15px;
                    &:hover, &:focus {
                        color: map-get($colors, main);
                    }
                }
            }
        }
    } 
}
